import { Button } from "@mui/material";
import { red } from "@mui/material/colors";
import { BuildersColDefs } from "../../../constants/builders/buildersColDefs";
import { useNavigate } from "react-router-dom";
import AddBusinessOutlinedIcon from "@mui/icons-material/AddBusinessOutlined";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { IBuilder } from "../../../@types/interface/Builder.interface";
import { api } from "../../../utils/api";
import DataGrid from "../../shared/dataGrid/DataGrid";
import UIContext from "../../../contexts/uiContext/UIContext";
import "./builders.css";
import BasicPagination from "../../shared/basicPagination/BasicPagination";
import Download from "../../shared/downloadbtn/Download";
import { FilterModel } from "ag-grid-community";
import { format } from "path";
import { formatFilters } from "../../../utils/commonFunction/formatFilter";

const data_per_page = 5;
const Builders = () => {
  const navigate = useNavigate();
  const { setDashboardHeader } = useContext(UIContext);
  const [rowData, setRowData] = useState<IBuilder[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPageCount, setTotalPageCount] = useState<number>(0);
  const [filters, setFilters] = useState<any>({});


  const onFilterChange = (filterModel: FilterModel) => {
		setFilters((prevFilters: any) => {
			const sanitizedFilters = { ...prevFilters };
			Object.keys(sanitizedFilters).forEach((key: any) => {
				if (!filterModel[key]) {
					delete sanitizedFilters[key];
				}
			});
			const updatedFilters = { ...sanitizedFilters, ...filterModel };
			console.log("Updated Filters-->", updatedFilters);
			return updatedFilters;
		});
	};
  const fetchbuilders = useCallback(
    async (filterQuery: any) => {
      try {
        const formattedFilter = formatFilters(filters);
        const filter = {
          ...filterQuery,
          ...formattedFilter,
          page: currentPage,
        };
        const response = await api.builder.getBuilder(filter);
        if (response) {
          setRowData(response.result);
          const totalResults = response.pagination.total;
          const updatedTotalPages = Math.ceil(totalResults / data_per_page);

          setTotalPageCount(updatedTotalPages);
          setCurrentPage(response.pagination.currentPage);
          console.log("response", response.pagination);
        }
      } catch (error) {
        console.error("Error while fetching data:", error);
      }
    },
    [currentPage, filters]
  );

  const fetchbuildersfullData = useCallback(async () => {
    try {
      const formattedFilter = formatFilters(filters);
      const filter = {
        ...formattedFilter,
        page: 0,
      };
      const response = await api.builder.getBuilder(filter);
      return response;
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  }, [filters]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    fetchbuilders({});
  }, [fetchbuilders]);

  useEffect(() => {
    setDashboardHeader("All Builders");
  }, []);
  return (
    <div className="builders-container">
      <div className="add-btn">
        <Download
          apiCall={fetchbuildersfullData}
          fileName="Builders"
          button_name="Download Builder Report"
          key={"download_builders"}
        />
        <Button
          variant="contained"
          className="btn"
          endIcon={<AddBusinessOutlinedIcon />}
          onClick={() => navigate("/add-builders")}
        >
          Add Builder
        </Button>
      </div>
      <DataGrid colDefs={BuildersColDefs} rowData={rowData} key={0} onFilterChange={onFilterChange} />

      <BasicPagination
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        pageCount={totalPageCount}
      />
    </div>
  );
};

export default Builders;
