import { Button, CircularProgress } from "@mui/material";
import DownloadingIcon from "@mui/icons-material/Downloading";
import { useState } from "react";
import { downloadExcel } from "../../../utils/commonFunction/downloadExcel";

const DownloadButton = ({
  apiCall,
  fileName,
  button_name,
}: {
  apiCall: any;
  fileName: string;
  button_name: string;
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleDownload = async () => {
    try {
      setLoading(true);
      const response = await apiCall();
      if (response) {
        downloadExcel(response.result, `${fileName}.xlsx`);
      }
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Button
      variant="contained"
      onClick={handleDownload}
      className="download-btn"
      endIcon={<DownloadingIcon />}
    >
      {loading ? <CircularProgress size={20} /> : button_name}
    </Button>
  );
};

export default DownloadButton;
