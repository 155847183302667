import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import UIContext from "../../../../contexts/uiContext/UIContext";
import SendIcon from '@mui/icons-material/Send';

const PhaseReport = () => {
  const { setDashboardHeader } = useContext(UIContext);

  useEffect(() => {
    setDashboardHeader("Phase Report");
  }, [setDashboardHeader]);
  return (
    <>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
      <h1>Coming Soon</h1>
    </div>
    </>
  );
};

export default PhaseReport;