import { ColDef } from "ag-grid-community";
import { BuilderCellRenderer } from "../../components/pages/builders/builderCellRenderer/BuilderCellRenderer";
import BuilderNameCellRenderer from "../../components/pages/builders/nameCellRenderer/NameCellRenderer";

export const BuildersColDefs: ColDef[] = [
  //   {
  //     field: "builder_name",
  //     headerName: "Builder's Name",
  //     suppressSizeToFit: true,
  //   },
  {
    field: "builder_name",
    headerName: "Builder's Name",
    cellRenderer: BuilderNameCellRenderer,
    suppressSizeToFit: true,
    floatingFilter: true,
    filter: "agTextColumnFilter",
  },
  {
    field: "builder_number",
    headerName: "Builder Number",
    suppressSizeToFit: true,
    floatingFilter: true,
    filter: "agTextColumnFilter",
  },
  { field: "cin_number", headerName: "CIN Number", suppressSizeToFit: true, floatingFilter: true , filter: "agTextColumnFilter"},
  {
    field: "number_projects",
    headerName: "Number of Projects",
    suppressSizeToFit: true,
    floatingFilter: true,
    filter: "agNumberColumnFilter",
  },
  {
    field: "contact_first_name",
    headerName: "Contact First Name",
    suppressSizeToFit: true,
    floatingFilter: true,
    filter: "agTextColumnFilter",
  },
  {
    field: "contact_last_name",
    headerName: "Contact Last Name",
    suppressSizeToFit: true,
    floatingFilter: true,
    filter: "agTextColumnFilter",
  },
  {
    field: "contact_phone_number",
    headerName: "Contact Phone No.",
    suppressSizeToFit: true,
    floatingFilter: true,
    filter: "agTextColumnFilter",
  },
  { field: "email", headerName: "Email", suppressSizeToFit: true , floatingFilter: true, filter: "agTextColumnFilter"},
  { field: "GST_number", headerName: "GST No.", suppressSizeToFit: true , floatingFilter: true, filter: "agTextColumnFilter"},
  { field: "PAN", headerName: "PAN No.", suppressSizeToFit: true, floatingFilter: true, filter: "agTextColumnFilter" },
  {
    field: "average_ratings",
    headerName: "Average Ratings",
    suppressSizeToFit: true,
  },
  { field: "no_ratings", headerName: "No Ratings", suppressSizeToFit: true },
  {
    field: "action",
    headerName: "Action",
    cellRenderer: BuilderCellRenderer,
    suppressSizeToFit: true,
  },
];
