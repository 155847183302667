import React, { useCallback, useContext, useEffect, useState } from "react";
import DataGrid from "../../../../../shared/dataGrid/DataGrid";
// import { FollowupColDefs } from "../../../../../../constants/folllowUp/followupColDefs";
import { Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
import BuilderContext from "../../../../../../contexts/builderContext/BuilderContext";
import { IProject } from "../../../../../../@types/interface/Projects";
import UIContext from "../../../../../../contexts/uiContext/UIContext";
import AuthContext from "../../../../../../contexts/authContext/authContext";
import { IFollowUp } from "../../../../../../@types/interface/followup.interface";
import { api } from "../../../../../../utils/api";
import { formatMongoDate } from "../../../../../../utils/commonFunction/formatMongoDate";
import { ICustomer } from "../../../../../../@types/interface/Customer.interface";
import { FollowupColDefs } from "../../../../../../constants/followup/FollowupColDefs";

const formatResponse = (response: any, customer: ICustomer) => {
  if (!response || !Array.isArray(response)) {
    return [];
  }

  // Format the response to match the column definitions
  return response.map((item: any) => ({
    full_name: customer?.full_name || "Unknown Customer",
    phone_number: customer?.phone || "N/A",
    followUp_date: formatMongoDate(item.followUp_date) || "N/A",
    followUp_note: item.followUp_note || "N/A",
    followUp_time: item.followUp_time || "N/A",
    created_by: item?.admin_details?.full_name || "Unknown",
  }));
};
const FollowUpDetails = ({
  data,
  customer,
}: {
  data: any;
  customer: ICustomer;
}) => {
  console.log("Data==>", data);
  console.log("Customer==>", customer);

  const navigate = useNavigate();
  const handleNavigateToFollowUpForm = () => {
    navigate(`/admin/add-follow-up?cid=${builderDetails?._id}&eid=${data}`);
  };
  const { user } = useContext(AuthContext);
  const { setDashboardHeader } = useContext(UIContext);
  const { builderDetails } = useContext(BuilderContext);
  const [followupList, setFollowupList] = useState<Partial<IFollowUp>[]>([]);
  const queryParams = new URLSearchParams(window.location.search);
  const enquiryId = queryParams.get("eid");

  const getFollowUp = useCallback(async () => {
    const filter = {
      followby_object_id: user?._id as string,
      enquiry_object_id: enquiryId as string,
    };
    const response = await api.followUp.getFollowUp(filter);
    const formatedResponse = formatResponse(response.result, customer);
    setFollowupList(formatedResponse);
  }, [user, enquiryId, customer]);

  useEffect(() => {
    setDashboardHeader("Follow up details");
  }, [setDashboardHeader]);

  useEffect(() => {
    getFollowUp();
  }, [getFollowUp]);
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "right",
          justifyContent: "right",
          marginBottom: "20px",
        }}
      >
        <Button
          variant="contained"
          className="btn"
          endIcon={<AddCircleOutlineIcon />}
          onClick={handleNavigateToFollowUpForm}
        >
          Add Follow Up
        </Button>
      </div>
      <DataGrid colDefs={FollowupColDefs} rowData={followupList} key={0} />
    </div>
  );
};

export default FollowUpDetails;
