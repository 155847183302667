import React, { useContext, useEffect, useState } from "react";
import { ChannelPartnerColDefs } from "./channelPartnerColDefs/ChannelPartnerColDefs";
import UIContext from "../../../../../contexts/uiContext/UIContext";
import DataGrid from "../../../../shared/dataGrid/DataGrid";

const ChannelPartnerLeads = () => {
  const { setDashboardHeader } = useContext(UIContext);
  const [details, setDetails] = useState([]);

  useEffect(() => {
    setDashboardHeader("Channel Partner Leads");
  }, [setDashboardHeader]);
  return (
    // <div>
    //   <DataGrid rowData={details} colDefs={ChannelPartnerColDefs} />
    // </div>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
      <h1>Coming Soon</h1>
    </div>
  );
};

export default ChannelPartnerLeads;
