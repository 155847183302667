import React, { useCallback, useContext, useEffect, useState } from "react";
import { FilterModel } from "ag-grid-community";
import UIContext from "../../../../contexts/uiContext/UIContext";
import { IPagination } from "../../../../@types/interface/pagination";
import useQuerySearch from "../../../hooks/querySearch/useQuerySearch";
import DataGrid from "../../../shared/dataGrid/DataGrid";
import { ProjectChangeReqColDefs } from "./projectChangeReqColDefs/ProjectChangeReqColDefs";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BuilderContext from "../../../../contexts/builderContext/BuilderContext";
import BasicPagination from "../../../shared/basicPagination/BasicPagination";
import useGetAllChangeRequests from "../../../../utils/hooks/useGetAllChangeRequest/useGetAllChangeRequest";

const ProjectChangeReq = () => {
  const { setDashboardHeader } = useContext(UIContext);
  const navigate = useNavigate();
  const { builderDetails } = useContext(BuilderContext);

 

  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    pageCount: 1,
  });

 const {rowData} = useGetAllChangeRequests(pagination, setPagination);


 
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPagination((prev) => ({
      ...prev,
      currentPage: value,
    }));
  };

  const handleNavigateToAddChangeReq = () => {
    navigate(`/admin/add-request?cid=${builderDetails?._id}`);
  };



  useEffect(() => {
    setDashboardHeader("Project Change Request");
  }, [setDashboardHeader]);
  return (
    <div>
      <div className="employee-container">
        <Button
          className="btn"
          style={{ fontSize: "15px" }}
          onClick={handleNavigateToAddChangeReq}
        >
          Add Change Request
        </Button>
      </div>

      <DataGrid rowData={rowData} colDefs={ProjectChangeReqColDefs} />
      <BasicPagination pageCount={pagination.pageCount} currentPage={pagination.currentPage} handlePageChange={handlePageChange}/>
    </div>
  );
};

export default ProjectChangeReq;
