import { Box, Tab, Tabs } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import a11yProps from "../../../../../constants/tabPanelProps/ayProps";
import TabPanel from "../../../../shared/tabPanel/TabPanel";
import UIContext from "../../../../../contexts/uiContext/UIContext";
import { api } from "../../../../../utils/api";
import CustomerDetails from "./customerDetails/CustomerDetails";
import ViewProject from "../../../../shared/viewProject/ViewProject";
import FollowUpDetails from "./followupDetails/FollowupDetails";

const EnquiryIframeDetails = ({
  mode,
}: {
  mode: "ENQUIRY" | "FORWARDED-ENQUIRY";
}) => {
  const [value, setValue] = useState<number>(0);
  const [bookingDetails, setBookingDetails] = useState<any>(null);
  const { setDashboardHeader } = useContext(UIContext);
  const queryParams = new URLSearchParams(window.location.search);
  const enquiryId = queryParams.get("eid");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleGetEnquiryDetails = useCallback(async () => {
    if (enquiryId) {
      // const filter = {
      //   book_object_id: enquiryId,
      // };
      // const response =
      //   mode === "ENQUIRY"
      //     ? await api.booking.getBookingDetails(filter)
      //     : await api.booking.getEnquiryDetails(filter);
      // console.log(response);
      // setBookingDetails(response);
    }
  }, [enquiryId, mode]);

  useEffect(() => {
    handleGetEnquiryDetails();
  }, [handleGetEnquiryDetails]);

  useEffect(() => {
    setDashboardHeader("IEnquiry Details");
  }, [setDashboardHeader]);
  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{
              style: {
                border: "none",
                backgroundColor: "#558b2f",
              },
            }}
            className="glb-tab-panel"
          >
            <Tab label="Customer Details" {...a11yProps(0)} />
            <Tab label="Project Details" {...a11yProps(1)} />
            <Tab label="Follow up" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {bookingDetails?.customer ? (
            <CustomerDetails customer={bookingDetails.customer} />
          ) : null}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {bookingDetails?.project ? (
            <ViewProject
              project={bookingDetails.project}
              projectId={bookingDetails.project._id}
            />
          ) : null}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {bookingDetails ? <FollowUpDetails data={enquiryId} /> : null}
        </TabPanel>
      </Box>
    </div>
  );
};

export default EnquiryIframeDetails;
