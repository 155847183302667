import { useCallback, useContext, useEffect, useState } from "react";
import { IPagination } from "../../../@types/interface/pagination";
import AuthContext from "../../../contexts/authContext/authContext";
import { ROLES } from "../../../constants/roles/Roles";
import { IBooking } from "../../../@types/interface/booking.interface";
import { api } from "../../../utils/api";
import { formatFilters } from "../../../utils/commonFunction/formatFilter";
import useQuerySearch from "../../../components/hooks/querySearch/useQuerySearch";

const useGetLatestLeads = (
  filters: any,
  pagination: IPagination,
  setPagination: React.Dispatch<React.SetStateAction<IPagination>>
) => {
  const { user } = useContext(AuthContext);
  const [rowData, setRowData] = useState<IBooking[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const builderId = useQuerySearch("cid");
  const getEnquiryData = useCallback(async () => {
    if (
      user?.role === ROLES.builder_admin ||
      ROLES.super_admin ||
      ROLES.project_manager ||
      ROLES.channel_partner ||
      ROLES.telecaller
    ) {
      const formattedFilter = formatFilters(filters);
      console.log("formattedFilter", formattedFilter);
      const filter = {
        ...formattedFilter,

        builder_object_id: builderId,
      };
      console.log("====>api", filter);
      const response = await api.iframe.getLatestLeads(filter);
      setRowData(response.result);
      setPagination(response.pagination);
    } else if (user?.role === "MARKETER" || "RESELLER") {
      const filter = {
        page: 1,
        sortField: "updatedAt",
        assigned_admin_object_id: user?._id,
      };
      console.log("====>api", filter);
      const response = await api.iframe.getLatestLeads(filter);
      setRowData(response.result);
      setPagination(response.pagination);
    } else {
      const filter = {
        page: 1,
        sortField: "updatedAt",
      };
      console.log("====>api", filter);
      const response = await api.iframe.getLatestLeads(filter);
      setRowData(response.result);
    }
  }, [pagination.currentPage, filters, builderId]);

  useEffect(() => {
    getEnquiryData();
  }, [getEnquiryData]);

  return { rowData, loading };
};

export default useGetLatestLeads;
