import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { ChangeEventHandler, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import UIContext from "../../../../contexts/uiContext/UIContext";
import SendIcon from "@mui/icons-material/Send";
import useGetAllProjectName from "../../../../utils/hooks/useGetAllProjectName/useGetAllProjectName";
import { IPagination } from "../../../../@types/interface/pagination";
import DownloadButton from "../../../shared/downloadbtn/Download";
import { useLocation } from "react-router-dom";
import { ROLES } from "../../../../constants/roles/Roles";
import BuilderContext from "../../../../contexts/builderContext/BuilderContext";
import AuthContext from "../../../../contexts/authContext/authContext";
import { api } from "../../../../utils/api";
import { IProjectName } from "../../../../@types/interface/projectName.interface";

const ProjectReport = () => {
  const { setDashboardHeader } = useContext(UIContext);
  const { builderDetails } = useContext(BuilderContext);
  const {user} = useContext(AuthContext);

  const { allProjectList } = useGetAllProjectName();
  console.log("---response", allProjectList)
  const [selectedProject, setSelectedProject] = useState("");
  const [alternativeReportName, setAlternativeReportName] = useState<string>("");

  const handleProjectChange = (
    event: SelectChangeEvent<string>,
    child: ReactNode 
  ) => {
    setSelectedProject(event.target.value as string);
   
  };


    const location = useLocation();
    
      const isOnAdminPage = location.pathname.startsWith("/admin");
      const isOnOwnerPage = location.pathname.startsWith("/landlord");
      const isOnRetailerPage = location.pathname.startsWith("/reseller");
      const isOnMarketerPage = location.pathname.startsWith("/marketer");
    
      // const handleNavigateToAddPlots = () => {
      //   navigate(`/admin/add-plots?cid=${builderDetails?._id}`);
      // };
      const getRole = () => {
        if (isOnAdminPage) {
          return ROLES.builder_admin;
        } else if (isOnOwnerPage) {
          return ROLES.landlord;
        } else if (isOnRetailerPage) {
          return ROLES.reseller;
        } else if (isOnMarketerPage) {
          return ROLES.marketer;
        }
      };
   
  const getAssignedProjectsReport = useCallback(async () => {
        try {
          let response: any = {};
    
            const filter = {
            role: getRole(),
            search_id: builderDetails ? builderDetails._id : user?._id,
            project_name: selectedProject,
            };
          response = await api.project.getAdminPlots(filter);
    
          if (response) {
            return response;
          }
        } catch (error) {
          console.error("Error while fetching data:", error);
        }
      }, [builderDetails, getRole, user?._id]);

const handleChange = (e:any) => {
  setAlternativeReportName(e.target.value)
}
  console.log("response", selectedProject)
  useEffect(() => {

    setDashboardHeader("Project Report");
  }, [setDashboardHeader]);
  return (
    <>
      <div>
        <h3 style={{marginBottom: "15px"}}>Project Wise Report Generation</h3>
        <Accordion defaultExpanded sx={{ marginTop: "20px", padding: "20px" }}>
          <AccordionDetails>
            <div
              className="input-field-container"
            >
              <label>Project Name:</label>
              <FormControl sx={{width: "600px"}} variant="outlined">
                <InputLabel id="project-select-label">
                  Select Project
                </InputLabel>
                <Select
                  labelId="project-select-label"
                  id="project-select"
                  value={selectedProject}
                  onChange={handleProjectChange}
                  label="Select Project"
                >
                  {allProjectList
                    ? allProjectList.map((project) => (
                        <MenuItem key={project._id} value={project.project_name}>
                          {project.project_name}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </div>
           
            <div
              className="input-field-container"
            >
              <label>Alternative Report Name:</label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                onChange={handleChange}
                sx={{ width: "600px", marginBottom: "20px" }}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
     
      <div className="button-container" style={{marginTop: "20px"}}>
    <DownloadButton
          apiCall={getAssignedProjectsReport}
          fileName={`${
            alternativeReportName? alternativeReportName : selectedProject
          } Project Report`}
          button_name="Download Project Report"
          key={"download_projects"}
        />
    </div>
    </>
  );
};

export default ProjectReport;
