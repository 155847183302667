import { useCallback, useContext, useEffect, useState } from "react";
import { IPagination } from "../../../@types/interface/pagination";
import AuthContext from "../../../contexts/authContext/authContext";
import { api } from "../../../utils/api";
import { IEnquiryIframe } from "../../../@types/interface/enquiryIframe.interface";
import { format } from "path";
import { formatFilters } from "../../../utils/commonFunction/formatFilter";

const useGetEnquiryIframeDetails = (
  filters: any,
  pagination: IPagination,
  setPagination: React.Dispatch<React.SetStateAction<IPagination>>,
  builderId: string
) => {
  const { user } = useContext(AuthContext);
  const [rowData, setRowData] = useState<
    IEnquiryIframe[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchEnquiryIframe = useCallback(async () => {
    try {
      const formattedFilter = formatFilters(filters);
      const filter = {
        ...formattedFilter,
        page: pagination.currentPage,
        builder_object_id: builderId,
      };
      const response = await api.iframe.getEnquiryIframe(filter);
      if (response) {
        setRowData(response.result);
        setPagination(response.pagination);
      }
    } catch (error) {
      console.error("error:", error);
    }
  }, [builderId, pagination.currentPage, filters]);

  useEffect(() => {
    fetchEnquiryIframe();
  }, [fetchEnquiryIframe]);

  return { rowData, loading };
};

export default useGetEnquiryIframeDetails;
