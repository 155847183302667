import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
} from "@mui/material";
import React, { useState } from "react";
import { IChangeModalProps } from "../../../@types/props/changeModal.props";
import { VisuallyHiddenInput } from "../../pages/admin/plots/addPlots/accordians/VisualInput";
import CancelIcon from "@mui/icons-material/Cancel";

const ChangeModal = ({ handleChangeModalOpen, open }: IChangeModalProps) => {
  const [textInput, setTextInput] = useState<string>("");
  const [imageFile, setImageFile] = useState<File[]>([]);

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextInput(event.target.value);
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    setImageFile((prevFiles) => [...prevFiles, ...files]);
  };
  const removeImage = (index: number) => {
    setImageFile((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };
  return (
    <div>
      <Dialog open={open} onClose={handleChangeModalOpen}>
        <DialogTitle>Request for change</DialogTitle>
        <DialogContent>
          <label>Add description:</label>
          <div className="input-container" style={{ width: "100%" }}>
            <TextareaAutosize
              onChange={handleTextChange}
              style={{ width: "500px" }}
            />
          </div>

          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            className="btn"
            style={{ marginBottom: "10px" }}
          >
            Upload Images
            <VisuallyHiddenInput
              type="file"
              multiple
              onChange={handleImageChange}
              required
            />
          </Button>
          {imageFile.length > 0 ? (
            <div style={{ display: "block", marginTop: "10px" }}>
              <label>Image Preview:</label>
              <div className="image-grid-box">
                {imageFile.map((file, index) => (
                  <div key={index} className="image-preview-container">
                    <img
                      src={URL.createObjectURL(file)}
                      alt={`Preview ${index + 1}`}
                      className="image-preview"
                    />
                    <Button
                      onClick={() => removeImage(index)}
                      className="remove-button"
                    >
                      <CancelIcon fontSize="small" />
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleChangeModalOpen} color="primary">
            Close
          </Button>
          <Button
            onClick={handleChangeModalOpen}
            color="primary"
            variant="contained"
            sx={{ margin: "15px" }}
          >
            Send request
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ChangeModal;
