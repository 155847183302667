import { ColDef } from "ag-grid-community";

export const FollowupColDefs: ColDef[] = [
  {
    field: "full_name",
    headerName: "Customer Name",
  },
  {
    field: "phone_number",
    headerName: "Phone number",
  },
  { field: "followUp_date", headerName: "Follow Up Date" },
  { field: "followUp_note", headerName: "Follow Up Note" },
  { field: "followUp_time", headerName: "Follow Up Time" },
  {
    field: "created_by",
    headerName: "Follow up created by",
  },
];
