import { Button } from "@mui/material";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import React from "react";
import ProjectNameCellRenderer from "../projectNameCellRenderer/ProjectNameCellRenderer";
import RequestByCellRenderer from "../requestByCellRenderer/RequestByCellRenderer";
import DateCellRenderer from "../dateCellRenderer/DateCellRenderer";


// Function to render a button
const renderActionButton = (props: ICellRendererParams) => {
  return (
    <Button
    variant="outlined"
    className="green-outlined-button"
    //onClick={handleRouteToEnquiryDetails}
  >
    See Details
  </Button>
  );
};

// Button click handler
const handleButtonClick = (params: ICellRendererParams) => {
  alert(`Button clicked for ${params.data.project_name}`);
};

export const ProjectChangeReqColDefs: ColDef[] = [
  {
    field: "subject",
    headerName: "Change Request Subject",
    flex: 1,
  },
  {
    field: "change_details",
    headerName: "Change Details",
    flex: 1,
  },
  {
    field: "supported_doc",
    headerName: "Supported Documents",
    flex: 1,
  },
  {
    field: "request_by",
    headerName: "Request By",
    cellRenderer: RequestByCellRenderer,
    flex: 1,
  },
  {
    field: "createdAt",
    headerName: "Request Date",
    cellRenderer: DateCellRenderer,
    flex: 1,
  },
];
