import { useCallback, useEffect, useState } from "react";
import { api } from "../../api";
import { IDashboard } from "../../../@types/interface/dashboardData.interface";
import useQuerySearch from "../../../components/hooks/querySearch/useQuerySearch";
import { IBuilderDashboard } from "../../../@types/interface/builderDashboard.interface";

const useGetBUildersDashboardData = () => {
  const [buildersDashboardData, setBuildersDashboardData] = useState<IBuilderDashboard | null>(null);
  const builderId = useQuerySearch("cid");
  const getDashboardData = useCallback(async () => {
    try{
        const filter = {
            builderId: builderId,
          };
        const response = await api.dashboard.getBuildersDashboardData(filter);
        if (response) {
          setBuildersDashboardData(response);
        }
    }catch(error){
        console.error("error:", error);
    }
   
  }, [builderId]);

  useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);

  return { buildersDashboardData };
};

export default useGetBUildersDashboardData;
