import * as XLSX from "xlsx";
import { replaceUnderScore } from "./replaceUnderScore";

export const downloadExcel = (
  jsonData: any[],
  fileName = "data.xlsx"
): void => {
  // Create a new workbook
  const workbook = XLSX.utils.book_new();

  // Convert JSON data to a worksheet
  const formattedData = jsonData.map((item: { [key: string]: any }) => {
    const formattedItem: { [key: string]: any } = {};
    for (const key in item) {
      if (item.hasOwnProperty(key)) {
        const formattedKey = replaceUnderScore(key);
        formattedItem[formattedKey] = item[key];
      }
    }
    return formattedItem;
  });

  const worksheet = XLSX.utils.json_to_sheet(formattedData);

  // Append the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Generate a binary string representation of the workbook
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  // Create a Blob from the binary string
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

  // Create a link element
  const link = document.createElement("a");

  // Set the download attribute with the desired file name
  link.href = URL.createObjectURL(blob);
  link.download = fileName;

  // Append the link to the document body
  document.body.appendChild(link);

  // Trigger the download by simulating a click
  link.click();

  // Clean up by removing the link element
  document.body.removeChild(link);
};
