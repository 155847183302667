import React, { useContext, useEffect } from 'react'
import UIContext from '../../../../contexts/uiContext/UIContext'

const PaymentPage = () => {
    const {setDashboardHeader} = useContext(UIContext);


    useEffect(() => {
        setDashboardHeader("Payments Details")
    }, [setDashboardHeader])
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
      <h1>Coming Soon</h1>
    </div>
  )
}

export default PaymentPage