import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { DataGridProp } from "../../../@types/props/DataGrid.props";
import './datagrid.css'
import { useCallback, useMemo, useRef } from "react";

interface IRow {
  make: string;
  model: string;
  price: number;
}

const DataGrid = ({ rowData, colDefs, onFilterChange, onRowSelected, handleCellValueChange } :DataGridProp) => {
  const gridApi = useRef<any>(null);
	const defaultColDef = useMemo(
		() => ({
			filterParams: {
				debounceMs: 500
			}
		}),
		[]
	);
  const onFilterChanged = useCallback(() => {
		if (gridApi.current) {
			const filterModel = gridApi.current.getFilterModel();
			
      if (onFilterChange) {
        onFilterChange(filterModel);
      }
		}
	}, [onFilterChange]);

  const handleRowClicked = useCallback(
		(params: any) => {
			if (onRowSelected && params.data && params.data._id) {
				onRowSelected(params.data._id);
			}
		},
		[onRowSelected]
	);
  return (
    <div className="ag-theme-alpine" style={{ height: "400px", width: "100%" }}>
      <AgGridReact
      onGridReady={(params: any) => {
        gridApi.current = params.api;
      }}
        animateRows={true}
        rowSelection="multiple"
        rowData={rowData}
        columnDefs={colDefs}
       onFilterChanged={onFilterChanged}
       defaultColDef={defaultColDef}
       onRowClicked={handleRowClicked}
        
      />
    </div>
  );
};

export default DataGrid;
