import React, { useContext, useEffect, useState } from 'react'
import { FilterModel } from 'ag-grid-community';
import UIContext from '../../../../../contexts/uiContext/UIContext';
import { IPagination } from '../../../../../@types/interface/pagination';
import useQuerySearch from '../../../../hooks/querySearch/useQuerySearch';
import useGetCustomerEnquiryDetails from '../../../../hooks/customerEnquiryDetails/useGetCustomerEnquiryDetails';
import { enquiryColumnDef } from '../../../../../constants/enquiry/EnquiryColDef';
import DataGrid from '../../../../shared/dataGrid/DataGrid';
import BasicPagination from '../../../../shared/basicPagination/BasicPagination';

const IncornersLeads = () => {
    const {setDashboardHeader} = useContext(UIContext);
    const [filters, setFilters] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState(null);
  
    const [pagination, setPagination] = useState<IPagination>({
      currentPage: 1,
      pageCount: 1,
    });

    const handlePageChange = (
        event: React.ChangeEvent<unknown>,
        value: number
      ) => {
        setPagination((prev) => ({
          ...prev,
          currentPage: value,
        }));
      };
      const onRowClicked = (event: { data: any }) => {
        const rowData = event.data;
        setSelectedRowData(rowData);
      };
 const onFilterChange = (filterModel: FilterModel) => {
      setFilters((prevFilters: any) => {
        const sanitizedFilters = { ...prevFilters };
        Object.keys(sanitizedFilters).forEach((key: any) => {
          if (!filterModel[key]) {
            delete sanitizedFilters[key];
          }
        });
        const updatedFilters = { ...sanitizedFilters, ...filterModel };
        console.log("Updated Filters-->", updatedFilters);
        return updatedFilters;
      });
    };

    const builderId = useQuerySearch("cid");
  
    const { rowData: bookingList } = useGetCustomerEnquiryDetails(
      filters,
      pagination,
      setPagination,
      builderId || ""
    );

    useEffect(() => {
        setDashboardHeader("Incorners Leads")
    },[setDashboardHeader])
  return (
    <div>
        <DataGrid rowData={bookingList} colDefs={enquiryColumnDef} 
          onFilterChange={onFilterChange}/>
        <BasicPagination
          currentPage={pagination.currentPage}
          handlePageChange={handlePageChange}
          pageCount={pagination.pageCount}
        />
    </div>
  )
}

export default IncornersLeads