import { CheckboxCellEditor, ColDef } from "ag-grid-community";
import LayoutImageCellRenderer from "../../components/pages/projects/cutomImageLayout";
import DataCellRenderer from "../../components/shared/cellRenderer/DataCellRenderer";
import DateCellRenderer from "../../components/shared/cellRenderer/DateCellRenderer";
import NameCellRenderer from "../../components/pages/admin/enquiry/nameCellRenderer/NameCellRenderer";
import CheckboxCellRenderer from "../../components/shared/cellRenderer/CheckboxCellRenderer";
import ForwardCellRenderer from "./forwardCellRenderer/ForwardCellRenderer";
import SeeDetailsCellRenderer from "../../components/pages/admin/plots/seeDetailsCellRenderer/SeeDetailsCellRenderer";
import EnquiryDetailsCellRenderer from "../../components/pages/admin/enquiry/enquiryDetailsCellrenderer/EnquiryDetailsCellRenderer";
import LeadStatusCellRenderer from "./leadStatus/LeadStatusCellRenderer";
import LeadSourceDataCellRenderer from "./leadSourceDataCellRenderer/LeadSourceDataCellRenderer";

export const enquiryColumnDef: ColDef[] = [
  {
    field: "customer.full_name",
    headerName: "Customer's Name",
    cellRenderer: NameCellRenderer,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "customer.phone",
    headerName: "Mobile",
    cellRenderer: DataCellRenderer,
    floatingFilter: true,
    filter: "agTextColumnFilter",
  },
  {
    field: "project.project_name",
    headerName: "Project Name",
    cellRenderer: DataCellRenderer,
    floatingFilter: true,
    filter: "agTextColumnFilter",
  },
  {
    field: "numberOfEnquiries",
    headerName: "Number of Enquries"
  },
  {
    field: "last_enquiry_date",
    headerName: "Last Enquiry Date",
    cellRenderer: DateCellRenderer,
  },
  {
    field: "followup_date",
    headerName: "FollowUp Date",
    cellRenderer: DateCellRenderer,
  },
  {
    field: "lead_type",
    headerName: "Lead Type",
  },
  {
    field: "builder.builder_name",
    headerName: "Builder's Name",
    cellRenderer: DataCellRenderer,
    floatingFilter: true,
    filter: "agTextColumnFilter",
  },
  {
    field: "builder.builder_name",
    headerName: "Forwarded to Project Manager",
    cellRenderer: ForwardCellRenderer,
    
  },
  {
    field: "layout_image",
    headerName: "Enquired Plot",
    suppressSizeToFit: true,
    // cellRenderer: LayoutImageCellRenderer,
    cellRenderer: (params: any) =>
      LayoutImageCellRenderer({
        ...params,
        projectId: params.data.project?._id,
        plotId: params.data?.plot?._id,
        mode: "ENQUIRY",
      }),
  },
  {
    field: "enqury_status",
    headerName: "See Details",
    cellRenderer: EnquiryDetailsCellRenderer,
  },
];
