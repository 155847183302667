/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
  useMemo,
} from "react";
import {
  Box,
  Button,
  Chip,
  Fab,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
} from "@mui/material";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import { api } from "../../../../../utils/api";
import UIContext from "../../../../../contexts/uiContext/UIContext";
import SaveIcon from "@mui/icons-material/Save";
import { PublishPlot } from "../../../../shared/publishPlot/PublishPlot";
import { ILayout } from "../../../../../@types/interface/layout.interface";
import BuilderContext from "../../../../../contexts/builderContext/BuilderContext";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../../../contexts/authContext/authContext";
import { ROLES } from "../../../../../constants/roles/Roles";
import { IViewLayoutProps } from "../../../../../@types/props/ViewLayout.props";
import PlotDetailsForm from "./plotDetailModal/PlotDetailsForm";
import { ISelect } from "../../../../../@types/interface/select.interface";
import "./Layout.css";
import AddIcon from "@mui/icons-material/Add";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import RestoreIcon from "@mui/icons-material/Restore";
import { VisuallyHiddenInput } from "./accordians/VisualInput";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const UploadLayout: React.FC<IViewLayoutProps> = ({
  project,
  projectId,
  layoutList,
  setLayoutList,
}) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [points, setPoints] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAddPlot, setIsAddPlot] = useState<boolean>(false);
  const { builderDetails } = useContext(BuilderContext);
  const { setDashboardHeader } = useContext(UIContext);
  const [originalImageWidth, setOriginalImageWidth] = useState<number>(0);
  const [savedPositions, setSavedPositions] = useState(new Set());
  const [originalImageHeight, setOriginalImageHeight] = useState(0);
  const [mode, setMode] = useState<"ASSIGN_PLOT" | "DEFAULT">("DEFAULT");
  const [builderList, setBuilderList] = useState<ISelect[]>([]);
  const [adminList, setAdminList] = useState<ISelect[]>([]);
  const [showPlotdet, setShowPlotdet] = useState(false);
  const [selectedPlot, setSelectPlot] = useState<string>("");
  const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 });
  const [bookedPlotsCount, setBookedPlotsCount] = useState<number>(0);
  const [blockedPlotsCount, setBlockedPlotsCount] = useState<number>(0);
  const [availablePlotsCount, setAvailablePlotsCount] = useState<number>(0);
  const [selectedPositions, setSelectedPositions] = useState<ILayout[]>([]);
  const [selectedPaths, setSelectedPaths] = useState<string[]>([]);
  const [isSelecting, setIsSelecting] = useState<boolean>(false);
  const [selectionStart, setSelectionStart] = useState<any>(null);
  const [selectionEnd, setSelectionEnd] = useState<any>(null);

  const [newlistedPlot, setNewlistedPlot] = useState<
    (ILayout | { assigned_to: string | null })[]
  >([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPoint, setSelectedPoint] = useState<ILayout | null>(null);
  const [selectedPointIndex, setSelectedPointIndex] = useState<number | null>(
    null
  );
  const [zoomLevel, setZoomLevel] = useState(1);
  const [assignObject, setAssignObject] = useState({
    assigned_user_role: "",
    assigned_to: "",
  });
  const [blockPhaseObject, setBlockPhaseObject] = useState<{
    block_number: string[];
    phase_number: string[];
  }>({
    block_number: [],
    phase_number: [],
  }); // state to store block_number and phase number for autoCompletion functionality in plotDetailsForm
  const [isMouseOnPoint, setIsMouseOnPoint] = useState<boolean>(false);
  const containerRef = useRef(null);

  const handleExtraArea = () => {
    setIsMouseOnPoint((prev) => !prev);
  };

  const handleSelect = (event: any, position: ILayout, index: number) => {
    const target = event.currentTarget;
    const rect = target.getBoundingClientRect();
    const alreadySelected = selectedPositions.some(
      (selectedPosition) => selectedPosition._id === position._id
    );

    if (alreadySelected) {
      setSelectedPositions((prevPositions) =>
        prevPositions.filter(
          (selectedPosition) => selectedPosition._id !== position._id
        )
      );
    } else {
      setSelectedPoint(position);
      setSelectedPointIndex(index);
      setTooltipPosition({
        left: rect.left + rect.width / 2,
        top: rect.top - 10,
      });
      setShowPlotdet(true);
      if (position._id) {
        setSelectPlot(position._id);
      }
      setModalOpen(true);
      // setSavedPositions((prevSaved) => new Set(prevSaved).add(position._id));
    }
  };

  const removeUnwantedPlot = () => {
    if (selectedPointIndex !== null) {
      setLayoutList((prevPositions) =>
        prevPositions.filter((_, index) => index !== selectedPointIndex)
      );
      handleCloseToolkit();
    }
  };

  const handleSavePlotDetails = (plot: ILayout, index: number) => {
    const previousPlot = [...layoutList];
    previousPlot[index] = plot;
    setLayoutList(previousPlot);
    setSavedPositions((prevSaved) => new Set(prevSaved).add(plot._id));
    handleCloseToolkit();
  };

  const handleMouseDown = (e: any) => {
    setIsSelecting(true);
    const svg = e.target.closest("svg");
    const point = svg.createSVGPoint();
    point.x = e.clientX;
    point.y = e.clientY;
    const svgPoint = point.matrixTransform(svg.getScreenCTM().inverse());
    setSelectionStart(svgPoint);
  };

  const handleMouseMove = (e: any) => {
    if (!isSelecting) return;
    const svg = e.target.closest("svg");
    const point = svg.createSVGPoint();
    point.x = e.clientX;
    point.y = e.clientY;
    const svgPoint = point.matrixTransform(svg.getScreenCTM().inverse());
    setSelectionEnd(svgPoint);
  };

  const handleMouseUp = () => {
    setIsSelecting(false);
    if (selectionStart && selectionEnd) {
      const selected = layoutList
        .filter((layout) => {
          const pathElement = layout._id
            ? document.getElementById(layout._id)
            : null;
          const bbox = (
            pathElement as unknown as SVGGraphicsElement
          )?.getBBox();
          return (
            bbox.x >= Math.min(selectionStart.x, selectionEnd.x) &&
            bbox.x + bbox.width <= Math.max(selectionStart.x, selectionEnd.x) &&
            bbox.y >= Math.min(selectionStart.y, selectionEnd.y) &&
            bbox.y + bbox.height <= Math.max(selectionStart.y, selectionEnd.y)
          );
        })
        .map((layout) => layout._id);
      setSelectedPaths(
        selected.filter((path): path is string => path !== undefined)
      );
    }
    setSelectionStart(null);
    setSelectionEnd(null);
  };

  const handleCloseToolkit = () => {
    setShowPlotdet(false);
  };

  const handleRemove = useCallback(() => {
    const updatedSelctions = layoutList.filter(
      (position: any) => !selectedPositions.includes(position)
    );
    setLayoutList(updatedSelctions);
  }, [layoutList, selectedPositions]);

  const getLayoutFromPath = (path: SVGPathElement): ILayout | null => {
    // Assuming paths have an ID attribute that corresponds to an ILayout ID
    const id = path.getAttribute("id");
    // console.log("===>id",id);
    // Implement logic to find the ILayout object by ID, e.g., from an array or a state
    // Example:
    return layoutList.find((layout) => layout._id === id) || null;
  };

  const getSelectedItems = (selectionBox: {
    startX: number;
    startY: number;
    endX: number;
    endY: number;
  }) => {
    const dotSize = 10; // Assuming the red dots are 10x10 pixels
    const container: any = containerRef.current;

    // Handle potential null reference for containerRef.current
    if (!container) {
      console.error("Container element not found!");
      return;
    }

    // console.log("===>start box",selectionBox.startX);

    // Get the bounding rectangle of the container element
    const containerRect = container.getBoundingClientRect();

    // Calculate the scale factor applied to the container (if any)
    const scaleX = containerRect.width / originalImageWidth;
    const scaleY = containerRect.height / originalImageHeight;

    // Calculate the adjusted coordinates of the selection box
    const adjustedSelectionTopLeftX =
      (selectionBox.startX - containerRect.left) / scaleX;
    const adjustedSelectionTopLeftY =
      (selectionBox.startY - containerRect.top) / scaleY;
    const adjustedSelectionBottomRightX =
      (selectionBox.endX - containerRect.left) / scaleX;
    const adjustedSelectionBottomRightY =
      (selectionBox.endY - containerRect.top) / scaleY;

    // Get all path elements
    const paths = container.querySelectorAll("path");

    // console.log("===>paths",paths);

    // Example mapping function to retrieve ILayout objects

    const newSelectedPositions: ILayout[] = [];

    paths.forEach((path: SVGPathElement) => {
      // Get the bounding box of the path
      const pathBBox = path.getBBox();

      // Adjust the path bounding box coordinates based on container scaling
      const pathLeft = pathBBox.x + containerRect.left;
      const pathTop = pathBBox.y + containerRect.top;
      const pathRight = pathLeft + pathBBox.width;
      const pathBottom = pathTop + pathBBox.height;

      // console.log("===>pathright",adjustedSelectionBottomRightX);

      // Calculate overlap area
      const overlapWidth = Math.max(
        0,
        Math.min(adjustedSelectionBottomRightX, pathRight) -
          Math.max(adjustedSelectionTopLeftX, pathLeft)
      );
      const overlapHeight = Math.max(
        0,
        Math.min(adjustedSelectionBottomRightY, pathBottom) -
          Math.max(adjustedSelectionTopLeftY, pathTop)
      );
      const overlapArea = overlapWidth * overlapHeight;

      // console.log("===>area",overlapWidth);

      // Check if there's any significant overlap between the path and the selection box
      if (overlapArea / (pathBBox.width * pathBBox.height) > 0.5) {
        const layout = getLayoutFromPath(path);
        // console.log("===>layout",layout);
        if (layout) {
          newSelectedPositions.push(layout);
        }
      }
    });
    // Update the state with the new selected positions
    setSelectedPositions(newSelectedPositions);
  };

  const handleChnagePlotViewMode = () => {
    setMode((prevMode) => (prevMode === "DEFAULT" ? "ASSIGN_PLOT" : "DEFAULT"));
  };
  const handleAssignChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAssignObject({
      ...assignObject,
      [e.target.name]: e.target.value,
    });
  };
  const handlePublishPlot = async () => {
    const payload = {
      project_object_id: projectId,
      layoutPosition: layoutList,
      newPlotList: newlistedPlot,
      status: "CONFIRMED",
      mode: mode,
    };
    const response = await api.project.updatePlotPostion(payload);
    if (response) {
      alert("Plots Published Successfully");
    }
  };

  const handleDraftPlot = async () => {
    let updatedLayoutList = layoutList;
    console.log("layout list===>", layoutList);

    const payload = {
      project_object_id: projectId,
      layoutPosition: updatedLayoutList,
      status: "DRAFT",
      newPlotList: newlistedPlot,
      mode: mode,
    };
    const response = await api.project.updatePlotPostion(payload);
    if (response) {
      setLayoutList(response);
      alert("Plots Saved Successfully");
      if (mode === "DEFAULT") {
        setMode("ASSIGN_PLOT");
      } else {
        setNewlistedPlot([]);
      }
    }
  };

  const handleBulkAssignPlot = async () => {
    try {
      let updatedLayoutList = layoutList;
      if (selectedPaths && layoutList) {
        // multiple plot assign conditional logic logic
        const selectedPathsSet = new Set(selectedPaths);

        updatedLayoutList = layoutList.map((layout) => {
          if (layout._id && selectedPathsSet.has(layout._id)) {
            return {
              ...layout,
              assigned_user_role: assignObject.assigned_user_role,
              assigned_to: assignObject.assigned_to,
            };
          }
          return layout;
        });
        setLayoutList(updatedLayoutList);
      }
      const payload = {
        project_object_id: projectId,
        layoutPosition: updatedLayoutList,
        status: "DRAFT",
        newPlotList: newlistedPlot,
        mode: mode,
      };
      const response = await api.project.updatePlotPostion(payload);
      if (response) {
        setLayoutList(response);
        alert("Plots Saved Successfully");
        if (mode === "DEFAULT") {
          setMode("ASSIGN_PLOT");
        } else {
          setNewlistedPlot([]);
        }
      }
    } catch (error) {
      console.error("Error while assigning plots", error);
      alert("Error while assigning plots");
    }
  };

  const countEditedPlots = useCallback(() => {
    let bookedCount = 0;
    let blockedCount = 0;
    const totalPlots = layoutList.length;
    console.log("====>totalPlots", totalPlots);
    layoutList?.forEach((plot) => {
      if (plot.is_blocked) {
        blockedCount++;
      } else if (plot.is_booked) {
        bookedCount++;
      }
    });
    setBookedPlotsCount(bookedCount);
    setBlockedPlotsCount(blockedCount);
    setAvailablePlotsCount(totalPlots - (bookedCount + blockedCount));
  }, [layoutList]);

  const getAdminAndBuilders = useCallback(async () => {
    const response = await api.project.getAdminAndBuilder();
    if (response) {
      setBuilderList(response.builder);
      setAdminList(response.admins);
    }
  }, []);

  const getPhaseNumberAndBlockNumber = useCallback(() => {
    if (layoutList && layoutList.length > 0) {
      // Extract unique phase_number and block_number
      const uniquePhases = Array.from(
        new Set(
          layoutList
            .map((item) => item.phase_number)
            .filter((phase) => phase !== null && phase !== "")
        )
      );
      const uniqueBlocks = Array.from(
        new Set(
          layoutList
            .map((item) => item.block_number)
            .filter((block) => block !== null && block !== "")
        )
      );

      // Update the state with unique values
      setBlockPhaseObject({
        block_number: uniqueBlocks,
        phase_number: uniquePhases,
      });
    }
  }, [layoutList]);

  useEffect(() => {
    getAdminAndBuilders();
    getPhaseNumberAndBlockNumber();
  }, [getPhaseNumberAndBlockNumber]);

  useEffect(() => {
    const handleScroll = () => {
      setShowPlotdet(false);
    };

    window.addEventListener("scroll", handleScroll);
    // if (isSelecting) {
    //   // eslint-disable-next-line array-callback-return
    //   layoutList.map((position: any) => {
    //     getSelectedItems(position);
    //   });
    // }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [getSelectedItems, isSelecting, layoutList]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // console.log("key pressed", event.key);
      if (event.key === "Delete" || event.key === "Backspace") {
        handleRemove();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleRemove]);

  useEffect(() => {
    const handleKeyDown = (e: any) => {
      if (e.key === "Backspace" || e.key === "Delete") {
        if (selectedPaths.length > 0) {
          console.log("Delete or Backspace pressed");
          const newLayoutList = layoutList.filter(
            (layout) => layout._id && !selectedPaths.includes(layout._id)
          );
          setLayoutList(newLayoutList);
          setSelectedPaths([]);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedPaths, setLayoutList]);

  useEffect(() => {
    setDashboardHeader("Confirm Plots");
  }, []);

  const phasing = [
    {
      value: "north",
      label: "NORTH",
    },
    {
      value: "south",
      label: "SOUTH",
    },
    {
      value: "east",
      label: "EAST",
    },
    {
      value: "west",
      label: "WEST",
    },
    {
      value: "north-west",
      label: "NORTH-WEST",
    },
    {
      value: "north-east",
      label: "NORTH-EAST",
    },
    {
      value: "south-west",
      label: "SOUTH-WEST",
    },
    {
      value: "south-east",
      label: "SOUTH-EAST",
    },
  ];

  const owner = [
    {
      value: "RESELLER",
      label: "Reseller",
    },
    {
      value: "BUILDER",
      label: "Builder",
    },
    {
      value: "LANDLORD",
      label: "Landlord",
    },
    {
      value: "MARKETER",
      label: "Marketer",
    },
  ];
  // Zoom in and out handlers
  const zoomIn = () => {
    setZoomLevel((prevZoom) => prevZoom + 0.1); // Limit max zoom level
  };

  const zoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.1, 1)); // Limit min zoom level
  };

  const resetPosition = () => {
    setZoomLevel(1);
  };

  // CSS Styling for the zoom container
  const containerStyle = {
    position: "relative",
    overflow: "hidden", // Prevent overflow when zooming
    width: "calc(75vw)", // Keep a fixed width relative to the viewport
    height: "auto",
    margin: "0 auto",
    transform: `scale(${zoomLevel})`, // Apply the zoom level
    transformOrigin: "center", // Zoom from the center of the image
    transition: "transform 0.3s ease", // Smooth zoom transition
  };
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const handleSubmit = async (uploadedFile: any) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("project_object_id", projectId);
      if (uploadedFile) {
        formData.append("layout", uploadedFile);
      }
      console.log(projectId);
      console.log(uploadedFile);
      if (formData) {
        console.log("====>form data: " + formData);
        const response = await api.project.uploadLayoutImageMedia(formData);
        console.log(response);
        window.window.location.reload();
        setLoading(false);
      } else {
        throw new Error(`API request failed`);
      }
    } catch (error) {
      console.error("Error while adding", error);
      alert("Error while submitting data");
      setLoading(false);
    }
  };

  const handleAddPlotToogle = () => {
    setPoints([]);
    if (isAddPlot) {
      const newPlotData: ILayout | { assigned_to: string | null } = {
        path: newPathData,
        is_blocked: false,
        is_booked: false,
        facing: "",
        plot_number: 0,
        plot_size: 0,
        price: 0,
        assigned_user_role: "",
        assigned_to: null,
        is_corner_plot: false,
        is_road_facing: false,
        has_bank_loan_available: false,
        has_emi_option: false,
        block_number: "",
        phase_number: "",
        land_type: "",
        area_type: "",
        project_object_id: projectId,
        saved: "",
        dimension: "",
        land_size: 0,
        villa_size: 0,
        farmhouse_size: 0,
        ground_floor_size: 0,
        first_floor_size: 0,
        second_floor_size: 0,
       no_of_trees: 0,
      };

      setNewlistedPlot((listedPlot) => [...listedPlot, newPlotData]);
    }
    setIsAddPlot((prev) => !prev);
  };

  const finishPath = () => {
    setIsAddPlot(false);
    setPoints([]);
    const newPlotData: ILayout | { assigned_to: string | null } = {
      path: newPathData,
      is_blocked: false,
      is_booked: false,
      facing: "",
      plot_number: 0,
      plot_size: 0,
      price: 0,
      assigned_user_role: "",
      assigned_to: null,
      is_corner_plot: false,
      is_road_facing: false,
      has_bank_loan_available: false,
      has_emi_option: false,
      block_number: "",
      phase_number: "",
      land_type: "",
      area_type: "",
      project_object_id: projectId,
      saved: "",
      dimension: "",
      land_size: 0,
      villa_size: 0,
      farmhouse_size: 0,
      ground_floor_size: 0,
      first_floor_size: 0,
      second_floor_size: 0,
     no_of_trees: 0,
    };

    setNewlistedPlot((listedPlot) => [...listedPlot, newPlotData]); // Functionally update the SVG path
  };

  const handleSVGClick = (e: any) => {
    if (!isAddPlot) return;
    const svg = e.target.closest("svg");
    const point = svg.createSVGPoint();
    point.x = e.clientX;
    point.y = e.clientY;
    const svgPoint = point.matrixTransform(svg.getScreenCTM().inverse());

    if (points.length > 0) {
      const lastPoint = points[points.length - 1].split(",").map(Number);
      const distance = Math.sqrt(
        Math.pow(svgPoint.x - lastPoint[0], 2) +
          Math.pow(svgPoint.y - lastPoint[1], 2)
      );

      if (distance > 500) {
        finishPath();
        return;
      }
    }
    setPoints([...points, `${svgPoint.x},${svgPoint.y}`]);
  };

  let newPathData = points.length > 0 ? `M ${points.join(" L ")} Z` : "";

  const handleSVGDrag = (e: any) => {
    if (!isAddPlot) return;
    const svg = e.target.closest("svg");
    const point = svg.createSVGPoint();
    point.x = e.clientX;
    point.y = e.clientY;
    const svgPoint = point.matrixTransform(svg.getScreenCTM().inverse());

    if (points.length > 0) {
      const lastPoint = points[points.length - 1].split(",").map(Number);
      const distance = Math.sqrt(
        Math.pow(svgPoint.x - lastPoint[0], 2) +
          Math.pow(svgPoint.y - lastPoint[1], 2)
      );

      if (distance > 250) {
        finishPath();
        return;
      }
    }
    setPoints([...points, `${svgPoint.x},${svgPoint.y}`]);
  };

  useEffect(() => {
    countEditedPlots();
  }, [countEditedPlots]);

  const layoutMap = useMemo(() => {
    return new Map(layoutList.map((layout) => [layout._id, layout]));
  }, [layoutList]);

  // Map selectedPaths (plot IDs) to plot numbers
  const selectedPlotNumbers = selectedPaths.map((id) => {
    const plot = layoutMap.get(id);
    if (plot) {
      return plot.plot_number != null ? plot.plot_number : "N/A";
    } else {
      return id;
    }
  });

  useEffect(() => {
    if (isAddPlot) {
      document.body.style.cursor = "crosshair";
    } else {
      document.body.style.cursor = "default";
    }
    return () => {
      document.body.style.cursor = "default";
    };
  }, [isAddPlot]);

  return (
    <>
      <Fab
        color="primary"
        aria-label="add"
        onClick={handleAddPlotToogle}
        style={{ position: "fixed", zIndex: 99, bottom: 16, right: 16 }}
      >
        {isAddPlot ? <SaveIcon /> : <AddIcon />}
      </Fab>
      <div className="plot-view">
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            alignItems: "right",
            marginBottom: "20px",
          }}
        >
          <Box
            sx={{
              borderRadius: "0.5rem",
              justifyContent: "space-between",
              gap: "20px",
              padding: "1rem 2rem",
              border: "0.5px solid #000",
              boxShadow: "0px 0px 3px 2px rgba(0,0,0,0.2)",
            }}
            display={"flex"}
          >
            <div
              style={{
                color: "#606061",
                fontWeight: 900,
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              <span style={{ fontSize: "1rem" }}>Blocked Plots:</span>
              <span style={{ fontSize: "1.6rem" }}>
                {blockedPlotsCount}
              </span>{" "}
              {/* Yellow color */}
            </div>
            <div
              style={{
                color: "green",
                fontWeight: 900,
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              <span style={{ fontSize: "1rem" }}>Available Plots:</span>
              <span style={{ fontSize: "1.6rem" }}>
                {availablePlotsCount}
              </span>{" "}
              {/* Yellow color */}
            </div>
            <div
              style={{
                color: "red",
                fontWeight: 900,
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              <span> Booked Plots:</span>
              <span style={{ fontSize: "1.2rem", color: "red" }}>
                {bookedPlotsCount}
              </span>{" "}
            </div>
          </Box>
        </div>
        <div className="button-container">
          {/* <Button onClick={handleRemove}>Remove Selections</Button> */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: 10,
            }}
          >
            {user?.role === ROLES.super_admin ? (
              <>
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<SaveIcon />}
                  sx={{
                    background: "black",
                    textAlign: "center",
                    fontSize: "12PX",
                  }}
                  onClick={handleDraftPlot}
                >
                  {newlistedPlot.length > 0 || mode === "DEFAULT"
                    ? "Save & Next"
                    : "Save Draft"}
                </Button>
                {newlistedPlot.length > 0 || mode === "DEFAULT" ? null : (
                  <PublishPlot handlePublishPlot={handlePublishPlot} />
                )}
              </>
            ) : null}
          </div>
        </div>
        <div className="absolute top-36 overflow-block z-[10] hidden sm:flex gap-5">
          {user?.role === ROLES.super_admin && (
            <div>
              <IconButton
                sx={{
                  backgroundColor: "#FF9037",
                  color: "white",
                  marginRight: 2,
                  marginBottom: 1,
                }}
                onClick={zoomIn}
              >
                <ZoomInIcon />
              </IconButton>
              <IconButton
                disabled={zoomLevel === 1}
                sx={{
                  backgroundColor: zoomLevel === 1 ? "#FF9037" : "#ff5a1f",
                  color: "white",
                  marginRight: 2,
                  marginBottom: 1,
                }}
                onClick={zoomOut}
              >
                <ZoomOutIcon />
              </IconButton>
              <IconButton
                sx={{ backgroundColor: "#FF9037", color: "white" }}
                onClick={resetPosition}
              >
                <RestoreIcon />
              </IconButton>

              <Button
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  marginLeft: 60,
                  marginRight: 20,
                }}
                endIcon={loading ? <></> : <SettingsBackupRestoreIcon />}
                className="btn"
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
              >
                {!loading ? <>Reupload Layout</> : <>Loading...</>}

                <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  onChange={(event: any) => {
                    const file = event.target.files?.[0];
                    if (file) {
                      // setUploadedFile(file);
                      handleSubmit(file);
                    }
                  }}
                  required
                />
              </Button>
            </div>
          )}
        </div>
        <div className="flex-space-between" style={{ paddingBottom: "10px" }}>
          {mode === "ASSIGN_PLOT" ? (
            <div style={{ width: "50%" }}>
              <TextField
                type="text"
                label="Selected Plot Numbers"
                value={selectedPlotNumbers.join(", ")} // comma seperated values
                disabled={true}
                fullWidth
              />
              <div className="flex-space-between" style={{ marginTop: "10px" }}>
                <TextField
                  select
                  id="owner-type"
                  label="Owner Type"
                  value={assignObject.assigned_user_role}
                  name="assigned_user_role"
                  variant="standard"
                  style={{ width: "40%", marginRight: "5px" }}
                  onChange={handleAssignChange}
                >
                  {owner.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  id="assigned_to"
                  label="Assigned To"
                  value={assignObject.assigned_to}
                  name="assigned_to"
                  variant="standard"
                  style={{ width: "40%" }}
                  onChange={handleAssignChange}
                >
                  {builderList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<CheckCircleIcon />}
                  onClick={handleBulkAssignPlot}
                  style={{ marginLeft: "5px" }}
                >
                  Assign
                </Button>
              </div>
            </div>
          ) : (
            <div style={{ width: "50%" }}></div> // Placeholder when TextField is not rendered
          )}
          <div className="flex-end">
            <div className="inline-flex">
              <FormControlLabel
                control={
                  <Switch
                    checked={mode === "ASSIGN_PLOT"}
                    onChange={handleChnagePlotViewMode}
                    name="plotModeSwitch"
                    color="primary"
                  />
                }
                label="Plot Details"
                labelPlacement="start"
              />
              <p>Plot Assigned</p>
            </div>
          </div>
        </div>
        {project?.layout_image && (
          <>
            {/* Container for zoomable content */}
            <div className="zoom-container" ref={containerRef}>
              {/* Inner content: Image and SVG */}
              <div
                className="zoom-content"
                style={{
                  transform: `scale(${zoomLevel})`,
                  transformOrigin: "center",
                }}
              >
                <img
                  src={project.layout_image}
                  alt="Plot Layout"
                  style={{ width: "100%", height: "100%", marginTop: "5px" }}
                  onLoad={(event) => {
                    const img = event.target as HTMLImageElement;
                    setOriginalImageWidth(img.naturalWidth);
                    setOriginalImageHeight(img.naturalHeight);
                  }}
                />
                <svg
                  className="svg-overlay"
                  onClick={handleSVGClick}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  viewBox={`0 0 ${originalImageWidth} ${originalImageHeight}`}
                >
                  {layoutList?.map((layout, index) => (
                    <path
                      key={index}
                      id={layout._id}
                      d={layout.path}
                      className={
                        layout._id && selectedPaths.includes(layout._id)
                          ? "selected"
                          : isAddPlot
                          ? "blocked"
                          : layout.is_blocked
                          ? "blocked"
                          : layout.is_booked
                          ? "booked"
                          : layout.plot_number && layout.dimension
                          ? "completed"
                          : "shape"
                      }
                      onClick={(e) => {
                        handleSelect(e, layout, index);
                      }}
                    />
                  ))}
                  {points.map((point, index) => {
                    const [x, y] = point.split(",").map(Number);
                    let cursorStyle = "crosshair";
                    if (points.length > 3) {
                      if (index === 0) cursorStyle = "nwse-resize";
                      else if (index === 1) cursorStyle = "nesw-resize";
                      else if (index === 2) cursorStyle = "nwse-resize";
                      else if (index === 3) cursorStyle = "nesw-resize";
                    }
                    return (
                      <circle
                        key={index}
                        cx={x}
                        cy={y}
                        r={22}
                        fill="orange"
                        onMouseMove={handleExtraArea}
                        style={{ cursor: cursorStyle }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                          setPoints((prevPoints) =>
                            prevPoints.filter((_, i) => i !== index)
                          );
                        }}
                      />
                    );
                  })}
                  {newPathData && (
                    <path
                      d={newPathData}
                      className="new-path"
                      onContextMenu={(e) => {
                        e.preventDefault();
                        setPoints([]);
                        // newPathData = "";
                      }}
                    />
                  )}

                  {newlistedPlot.map(
                    (plotData, index) =>
                      "path" in plotData && (
                        <path
                          key={index}
                          d={plotData.path}
                          className="new-path"
                        />
                      )
                  )}
                  {isSelecting && selectionStart && selectionEnd && (
                    <>
                      <rect
                        x={Math.min(selectionStart.x, selectionEnd.x)}
                        y={Math.min(selectionStart.y, selectionEnd.y)}
                        width={Math.abs(selectionEnd.x - selectionStart.x)}
                        height={Math.abs(selectionEnd.y - selectionStart.y)}
                        fill="rgba(0, 0, 255, 0.3)"
                        stroke="blue"
                      />
                    </>
                  )}
                </svg>
              </div>
            </div>
          </>
        )}

        {showPlotdet &&
          selectedPoint &&
          selectedPointIndex && ( // this is the tooltip for the clicked one
            <div
              style={{
                position: "fixed",
                left: tooltipPosition.left,
                top: tooltipPosition.top,
                transform: "translate(-50%, -100%)",
                backgroundColor: "white",
                color: "black",
                padding: "5px 10px",
                borderRadius: "5px",
                border: "1px solid gray",
              }}
              onMouseEnter={() => {
                setShowPlotdet(true);
              }}
            >
              <PlotDetailsForm
                index={selectedPointIndex}
                phasingOptions={phasing}
                ownerOptions={owner}
                builderOptions={builderList}
                handleSavePlotDetails={handleSavePlotDetails}
                adminOptions={adminList}
                blockPhaseOptions={blockPhaseObject}
                selectedPoint={selectedPoint}
                handleCloseToolkit={handleCloseToolkit}
                removeUnwantedPlot={removeUnwantedPlot}
                handleSave={undefined}
                mode={mode}
              />

              <div
                style={{
                  width: "0",
                  height: "0",
                  borderLeft: "10px solid transparent",
                  borderRight: "10px solid transparent",
                  borderTop: "10px solid white",
                  position: "absolute",
                  left: "50%",
                  bottom: "-10px",
                  transform: "translateX(-50%)",
                }}
              />
            </div>
          )}

         
     
      </div>
      {/* </SpeedDialTooltipOpen> */}
    </>
  );
};

export default UploadLayout;
