import { ColDef } from "ag-grid-community";

export const UpdatedPlotColDefs : ColDef[] = [
    

{
    headerName: "Facing",
    field: "facing",
    filter: 'agTextColumnFilter', // Enable text filtering
    floatingFilter: true,
},
{
    headerName: "Plot Number",
    field: "plot_number",
  
    filter: 'agTextColumnFilter', // Enable number filtering
    floatingFilter: true,
      
},
{
    headerName: "Plot Size",
    field: "plot_size",
    filter: 'agNumberColumnFilter', // Enable number filtering
    floatingFilter: true,
},
{
    headerName: "Plot Status",
    field: "plot_status",
    filter: 'agTextColumnFilter', // Enable text filtering
    floatingFilter: true,
},
{
    headerName: "Price",
    field: "price"
},
{
    headerName: "Assigned User Role",
    field: "assigned_user_role",
    filter: 'agTextColumnFilter', // Enable text filtering
    floatingFilter: true,
},
{
    headerName: "Assigned To",
    field: "assigned_to"
},


{
    headerName: "Block Number",
    field: "block_number",
    filter: 'agTextColumnFilter', // Enable text filtering
    floatingFilter: true,
},
{
    headerName: "Phase Number",
    field: "phase_number",
    filter: 'agTextColumnFilter', // Enable text filtering
    floatingFilter: true,
},
{
    headerName: "Land Type",
    field: "land_type",
    filter: 'agTextColumnFilter', // Enable text filtering
    floatingFilter: true,
},
{
    headerName: "Area Type",
    field: "area_type"
},
{
    headerName: "Dimension",
    field: "dimension"
},
{
    headerName: "Number of Trees",
    field: "no_of_trees"
},
{
    headerName: "Land Size (sqyds)",
    field: "land_size",
    filter: 'agNumberColumnFilter', // Enable number filtering
    floatingFilter: true,
},
{
    headerName: "Farmhouse Size (sqft)",
    field: "farmhouse_size",
    filter: 'agNumberColumnFilter', // Enable number filtering
    floatingFilter: true,
},
{
    headerName: "Villa Size (sqft)",
    field: "villa_size",
    filter: 'agNumberColumnFilter', // Enable number filtering
    floatingFilter: true,
},
{
    headerName: "Ground Floor Size (sqft)",
    field: "ground_floor_size",
    filter: 'agNumberColumnFilter', // Enable number filtering
    floatingFilter: true,
},
{
    headerName: "First Floor Size (sqft)",
    field: "first_floor_size",
    filter: 'agNumberColumnFilter', // Enable number filtering
    floatingFilter: true,
},
{
    headerName: "Second Floor Size (sqft)",
    field: "second_floor_size",
    filter: 'agNumberColumnFilter', // Enable number filtering
    floatingFilter: true,
}
]