import React, { ReactNode, useContext, useEffect, useState } from "react";
import { ProjectLeadsColDefs } from "./projectLeadColDefs/projectLeadsColDefs";
import UIContext from "../../../../../contexts/uiContext/UIContext";
import DataGrid from "../../../../shared/dataGrid/DataGrid";
import { enquiryColumnDef } from "../../../../../constants/enquiry/EnquiryColDef";
import ProjectAutoCompleteSearchField from "../../../../shared/projectAutoCompleteSearchField/ProjectAutoCompleteSearchField";
import { IProject } from "../../../../../@types/interface/Projects";
import useQuerySearch from "../../../../hooks/querySearch/useQuerySearch";
import { IassignedProject } from "../../../../../@types/interface/assignedProjectList";
import useGetProjectWiseLead from "../../../../../utils/hooks/getProjectwiseLead/useGetProjectWiseLead";
import { IPagination } from "../../../../../@types/interface/pagination";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import useGetAllProjectName from "../../../../../utils/hooks/useGetAllProjectName/useGetAllProjectName";

const ProjectLeads = () => {
  const { setDashboardHeader } = useContext(UIContext);
  const [filters, setFilters] = useState({});
  // const [selectedprojectObjectId, setSelectedProjectObjectId] = useState<string>("6717729e1f62bce244a97655");
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    pageCount: 1,
  });
  const { allProjectList } = useGetAllProjectName();
  console.log("---response", allProjectList)
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const { rowData } = useGetProjectWiseLead(
    filters,
    pagination,
    selectedProjectId,
    setPagination
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPagination((prev) => ({
      ...prev,
      currentPage: value,
    }));
  };


    const handleProjectChange = (
      event: SelectChangeEvent<string>,
      child: ReactNode 
    ) => {
      setSelectedProjectId(event.target.value as string);
     
    };

  useEffect(() => {
    setDashboardHeader("Project Leads");
  }, [setDashboardHeader]);
  return (
    <div>
      <h3>Select Project Name</h3>
      <FormControl sx={{width: "600px"}} variant="outlined">
                <InputLabel id="project-select-label">
                  Select Project
                </InputLabel>
                <Select
                  labelId="project-select-label"
                  id="project-select"
                  value={selectedProjectId}
                  onChange={handleProjectChange}
                  label="Select Project"
                >
                  {allProjectList
                    ? allProjectList.map((project) => (
                        <MenuItem key={project._id} value={project._id}>
                          {project.project_name}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
      <div style={{ marginBottom: "30px" }}>
        {/* <ProjectAutoCompleteSearchField setProjectId={handleProjectSelect} /> */}
      </div>

      <p style={{ fontWeight: "500", marginBottom: "25px" }}>
        Project Wise Leads Details
      </p>
      <DataGrid rowData={rowData} colDefs={enquiryColumnDef} />
    </div>
  );
};

export default ProjectLeads;
