export const AREA_TYPES_OPTIONS = [
    {
        label: "Land",
        value: "LAND",
    },
    {
        label: "Land + Farm House",
        value: "LAND+FARMHOUSE",
    },
    {
        label: "Land + Trees",
        value: "LAND+TREES",
    },
    
    {
        label: "Land + Villa",
        value: "LAND+VILLA",
    },
   
    {
        label: "Land + Villa + 1",
        value: "LAND+VILLA+1",
    },
    {
        label: "Land + Villa +2",
        value: "LAND+VILLA+2",
    },
]