import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import UIContext from '../../../../contexts/uiContext/UIContext'
import { BarChart, DashboardCard, LineChart, PieChart } from '../../../shared';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ApartmentIcon from '@mui/icons-material/Apartment';
import GroupsIcon from '@mui/icons-material/Groups';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import useQuerySearch from '../../../hooks/querySearch/useQuerySearch';
import useGetBUildersDashboardData from '../../../../utils/hooks/buildersDashboardData/useGetBuildersDashboardData';
import { Box, IconButton, Switch } from '@mui/material';
import { formatMongoDate } from '../../../../utils/commonFunction/formatMongoDate';
import useGetDailyLeads from '../../../../utils/hooks/useGetDailyLeads/useGetDailyLeads';
import { sevenDayNext, sevenDayPrev, todayDate } from '../../../../utils/commonFunction/dateCalculation';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const BuildersDashboard = () => {
  const {setDashboardHeader} = useContext(UIContext);
  const {buildersDashboardData} = useGetBUildersDashboardData();
    const [switchValue, setSwitchValue] = useState<boolean>(false);
    const [leadsSearchDate,setLeadsSearchDate] = useState<string>(todayDate());


      const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSwitchValue(event.target.checked);
      };

      const {leadsData} = useGetDailyLeads({date: leadsSearchDate});

      const handleChangeLeadsSearchDate = (type:"PREV" | "NEXT") => {
        if(type === "PREV"){
          const sevenDayAgo = sevenDayPrev(leadsSearchDate);
          setLeadsSearchDate(sevenDayAgo);
        }
        else{
          const nextSevenDay = sevenDayNext(leadsSearchDate);
          setLeadsSearchDate(nextSevenDay);
        }
      }
    
      const lead = [
        {
          id: "Total Leads",
          label: "total leads",
          value: 215,
          color: "hsl(272, 70%, 50%)",
        },
        {
          id: "Channel Partner",
          label: "Channel Partner",
          value: 0,
          color: "hsl(157, 70%, 50%)",
        },
        {
          id: "Incorners Leads",
          label: "incorners leads",
          value: 342,
          color: "hsl(52, 70%, 50%)",
        },
        {
          id: "Outside Leads",
          label: "incorners leads",
          value: 342,
          color: "hsl(52, 70%, 50%)",
        },
      ];

      const builder = [
        
        {
          id: "Channel Partner",
          label: "Channel Partner",
          value: buildersDashboardData?.channelPartnerLeads || 0,
          color: "hsl(157, 70%, 50%)",
        },
        {
          id: "Incorners Leads",
          label: "Incorners Leads",
          value: buildersDashboardData?.numberOfIncornersLeads || 0,
          color: "hsl(52, 70%, 50%)",
        },
        {
          id: "Outside Leads",
          label: "Outside Leads",
          value: buildersDashboardData?.numberOfOutsideLeads || 0,
          color: "hsl(198, 70%, 50%)",
        },
       
      ];

      const lineChart = [
        {
          id: "leads",
          color: "hsl(293, 70%, 50%)",
          data: [
            {
              x: "Day 1",
              y: 23,
            },
            {
              x: "Day 2",
              y: 170,
            },
            {
              x: "Day 3",
              y: 86,
            },
            {
              x: "Day 4",
              y: 228,
            },
            {
              x: "Day 5",
              y: 46,
            },
            {
              x: "Day 6",
              y: 112,
            },
            {
              x: "Day 7",
              y: 32,
            },
          ],
        },
      ];

      const barChart = [
        {
          day: "Day 1",
          value: 190,
          color: "hsl(326, 70%, 50%)",
        },
        {
          day: "Day 2",
          value: 141,
          color: "hsl(132, 70%, 50%)",
        },
        {
          day: "Day 3",
          value: 56,
          color: "hsl(227, 70%, 50%)",
        },
        {
          day: "Day 4",
          value: 191,
          color: "hsl(286, 70%, 50%)",
        },
        {
          day: "Day 5",
          value: 163,
          color: "hsl(227, 70%, 50%)",
        },
        {
          day: "Day 6",
          value: 120,
          color: "hsl(209, 70%, 50%)",
        },
        {
          day: "Day 7",
          value: 4,
          color: "hsl(346, 70%, 50%)",
        },
      ];
  useEffect(() => {
    setDashboardHeader("Dashboard Analytics")
  }, [setDashboardHeader])
  return (
    <div className='home-page'>
       <div className="cards-section">
        <Box sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: "center", 
        gap: "0.5rem", 
        padding: "1rem",
      }}>

<DashboardCard
          cardTitle={"Total Projects"}
          cardValue={buildersDashboardData?.numberOfAssignedProjects || 0}
          cardIcon={<AssignmentTurnedInIcon sx={{ color: "#153448" }} />}
        />
        <DashboardCard
          cardTitle={"Total Layouts"}
          cardValue={0}
          cardIcon={<ApartmentIcon sx={{ color: "#153448" }} />}
        />
        <DashboardCard
          cardTitle={"Total Leads"}
          cardValue={buildersDashboardData ? (buildersDashboardData.numberOfIncornersLeads+ buildersDashboardData.numberOfOutsideLeads) : 0}
          cardIcon={<GroupsIcon sx={{ color: "#153448" }} />}
        />
        <DashboardCard
          cardTitle={"Total Bookings"}
          cardValue={buildersDashboardData?.numberOfBookings || 0}
          cardIcon={<BookmarkAddedIcon sx={{ color: "#153448" }} />}
        />
        </Box>
     
      </div>
      <div className='mid-section' >
      <div className="bigchart-container">
          <div className="top-section">
            <h2>
              {"Total Leads from Builder"}
            </h2>
           
          </div>
          <div className="bottom-section">
          
              <PieChart pieChartData={builder} />
            {/* <div className="minor-charts">
              <div className="minor-charts-top">
                <DonutChart />
              </div>
              <div className="minor-charts-bottom">
                <DonutChart />
              </div>
            </div> */}
      
          </div>
          <div className="two-charts-container">
          
          <div className="bottom-chart">
            <div className="top-section">
              <h2>Leads Stats</h2>
            </div>
            <div className="bottom-section">
            {
									leadsData.length>0 ?
									<p style={{height: "0px", textAlign: "center"}}>
										<IconButton onClick={()=>{handleChangeLeadsSearchDate("PREV")}}><ChevronLeftIcon fontSize={"small"}/></IconButton>
										<span>{formatMongoDate(leadsData[0].date)} </span>
										-
										<span>{formatMongoDate(leadsData[leadsData.length-1].date)}</span>
										<IconButton onClick={()=>{handleChangeLeadsSearchDate("NEXT")}}><ChevronRightIcon fontSize={"small"}/></IconButton>
									</p>: null
								}
              <BarChart barChartData={leadsData} />
            </div>
          </div>
        </div>
        
        </div>
      
      </div>
     
    
    </div>
  )
}

export default BuildersDashboard