import React, { ChangeEvent, useContext, useEffect, useState } from "react";

import "./homePage.css";
import {
  Box,
  Card,
  IconButton,
  Switch,
  Theme,
  createStyles,
  makeStyles,
} from "@mui/material";
import {
  BarChart,
  DashboardCard,
  DonutChart,
  LineChart,
  Map,
  PieChart,
} from "../../shared";
import ViewStreamIcon from "@mui/icons-material/ViewStream";
import LandscapeIcon from "@mui/icons-material/Landscape";
import PeopleIcon from "@mui/icons-material/People";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { red } from "@mui/material/colors";
import UIContext from "../../../contexts/uiContext/UIContext";
import useGetDashboard from "../../../utils/hooks/useGetdashboardData/useGetDashboard";
import { sevenDayNext, sevenDayPrev, todayDate } from "../../../utils/commonFunction/dateCalculation";
import useGetDailyLeads from "../../../utils/hooks/useGetDailyLeads/useGetDailyLeads";
import { formatMongoDate } from "../../../utils/commonFunction/formatMongoDate";


const Dashboard = () => {
  const [switchValue, setSwitchValue] = useState<boolean>(false);
  const { setDashboardHeader } = useContext(UIContext);
  const [leadsSearchDate,setLeadsSearchDate] = useState<string>(todayDate());
  
  const { dashboardData } = useGetDashboard();
  const {leadsData} = useGetDailyLeads({date: leadsSearchDate});

  const handleChangeLeadsSearchDate = (type:"PREV" | "NEXT") => {
		if(type === "PREV"){
			const sevenDayAgo = sevenDayPrev(leadsSearchDate);
			setLeadsSearchDate(sevenDayAgo);
		}
		else{
			const nextSevenDay = sevenDayNext(leadsSearchDate);
			setLeadsSearchDate(nextSevenDay);
		}
	}

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSwitchValue(event.target.checked);
  };

  const lead = [
    {
      id: "Website",
      label: "Website",
      value: 215,
      color: "hsl(272, 70%, 50%)",
    },
    {
      id: "Channel Partner",
      label: "Channel Partner",
      value: 497,
      color: "hsl(157, 70%, 50%)",
    },
    {
      id: "Others",
      label: "Others",
      value: 342,
      color: "hsl(52, 70%, 50%)",
    },
  ];
  const builder = [
    {
      id: "Builder 1",
      label: "Builder 1",
      value: 215,
      color: "hsl(272, 70%, 50%)",
    },
    {
      id: "Builder 2",
      label: "Builder 2",
      value: 497,
      color: "hsl(157, 70%, 50%)",
    },
    {
      id: "Builder 3",
      label: "Builder 3",
      value: 342,
      color: "hsl(52, 70%, 50%)",
    },
    {
      id: "Builder 4",
      label: "Builder 4",
      value: 373,
      color: "hsl(198, 70%, 50%)",
    },
    {
      id: "Builder 5",
      label: "Builder 5",
      value: 534,
      color: "hsl(222, 70%, 50%)",
    },
  ];
  const lineChart = [
    {
      id: "leads",
      color: "hsl(293, 70%, 50%)",
      data: [
        {
          x: "Day 1",
          y: 23,
        },
        {
          x: "Day 2",
          y: 170,
        },
        {
          x: "Day 3",
          y: 86,
        },
        {
          x: "Day 4",
          y: 228,
        },
        {
          x: "Day 5",
          y: 46,
        },
        {
          x: "Day 6",
          y: 112,
        },
        {
          x: "Day 7",
          y: 32,
        },
      ],
    },
  ];

  const barChart = [
    {
      day: "Day 1",
      value: 190,
      color: "hsl(326, 70%, 50%)",
    },
    {
      day: "Day 2",
      value: 141,
      color: "hsl(132, 70%, 50%)",
    },
    {
      day: "Day 3",
      value: 56,
      color: "hsl(227, 70%, 50%)",
    },
    {
      day: "Day 4",
      value: 191,
      color: "hsl(286, 70%, 50%)",
    },
    {
      day: "Day 5",
      value: 163,
      color: "hsl(227, 70%, 50%)",
    },
    {
      day: "Day 6",
      value: 120,
      color: "hsl(209, 70%, 50%)",
    },
    {
      day: "Day 7",
      value: 4,
      color: "hsl(346, 70%, 50%)",
    },
  ];

  useEffect(() => {
    setDashboardHeader("Dashboard");
  }, [setDashboardHeader]);
  return (
    <div className="home-page">
      <div className="cards-section">
        <DashboardCard
          cardTitle={"Total Project"}
          cardValue={dashboardData?.numberOfActiveProjects || 0}
          cardIcon={<LandscapeIcon sx={{ color: "#153448" }} />}
        />
        <DashboardCard
          cardTitle={"Total Builders"}
          cardValue={dashboardData?.numberOfBuilders || 0}
          cardIcon={<PeopleIcon sx={{ color: "#153448" }} />}
        />
        <DashboardCard
          cardTitle={"Total Leads"}
          cardValue={dashboardData ? (dashboardData.numberOfIncornersLeads+ dashboardData.numberOfOutsideLeads) : 0}
          cardIcon={<LeaderboardIcon sx={{ color: "#153448" }} />}
        />
        <DashboardCard
          cardTitle={"Total Bookings"}
          cardValue={dashboardData?.numberOfBookings || 0}
          cardIcon={<BookmarkIcon sx={{ color: "#153448" }} />}
        />
      </div>
      <div className="mid-section">
        <div className="bigchart-container">
          <div className="top-section">
            <h2>
              {switchValue ? "Leads Generation" : "Total Leads from Builder"}
            </h2>
            <div className="switch-container">
              <Switch
                checked={switchValue}
                onChange={handleSwitchChange}
                inputProps={{ "aria-label": "controlled" }}
                sx={{
                  "& .MuiSwitch-thumb": {
                    color: "#2a7662",
                  },
                  "& .Mui-checked + .MuiSwitch-track": {
                    backgroundColor: "#153448",
                  },
                  "&  .MuiSwitch-track": {
                    backgroundColor: "#153448",
                  },
                }}
              />
            </div>
          </div>
          <div className="bottom-section">
            {switchValue ? (
              <PieChart pieChartData={lead} />
            ) : (
              <PieChart pieChartData={builder} />
            )}
            {/* <div className="minor-charts">
              <div className="minor-charts-top">
                <DonutChart />
              </div>
              <div className="minor-charts-bottom">
                <DonutChart />
              </div>
            </div> */}
          </div>
        </div>
        <div className="two-charts-container">
          <div className="top-chart">
            <div className="top-section">
              <h2>Lead Stats</h2>
            </div>
            <div className="bottom-section">
              <LineChart lineChartData={lineChart} />
            </div>
          </div>
          <div className="bottom-chart">
            <div className="top-section">
              <h2>Leads Stats</h2>
            </div>
            <div className="bottom-section">
            {
									leadsData.length>0 ?
									<p style={{height: "0px", textAlign: "center"}}>
										<IconButton onClick={()=>{handleChangeLeadsSearchDate("PREV")}}><ChevronLeftIcon fontSize={"small"}/></IconButton>
										<span>{formatMongoDate(leadsData[0].date)} </span>
										-
										<span>{formatMongoDate(leadsData[leadsData.length-1].date)}</span>
										<IconButton onClick={()=>{handleChangeLeadsSearchDate("NEXT")}}><ChevronRightIcon fontSize={"small"}/></IconButton>
									</p>: null
								}
              <BarChart barChartData={leadsData} />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="map-section">
        <div className="top-section">
          <h2>Customer Location</h2>
        </div>
        <div className="bottom-section">
          <Map />
        </div>
      </div> */}
    </div>
  );
};

export default Dashboard;
