import { useCallback, useContext, useEffect, useState } from "react";
import { IPagination } from "../../../@types/interface/pagination";
import AuthContext from "../../../contexts/authContext/authContext";
import { ROLES } from "../../../constants/roles/Roles";
import { IBooking } from "../../../@types/interface/booking.interface";
import { api } from "../../../utils/api";
import { formatFilters } from "../../../utils/commonFunction/formatFilter";

const useGetCustomerEnquiryDetails = (
	filters: any,
	pagination: IPagination,
	setPagination: React.Dispatch<React.SetStateAction<IPagination>>,
	builderId: string
) => {
    const {user} = useContext(AuthContext);
	const [rowData, setRowData] = useState<IBooking[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const getEnquiryData = useCallback(async () => {
        if (
          user?.role === ROLES.builder_admin ||
          ROLES.super_admin ||
          ROLES.project_manager ||
          ROLES.channel_partner ||
          ROLES.telecaller
        ) {
          const formattedFilter = formatFilters(filters);
          console.log("formattedFilter", formattedFilter);
          const filter = {
            ...formattedFilter,
            page: pagination.currentPage,
            sortField: "updatedAt",
            builder_object_id: builderId,
          };
          console.log("====>api", filter);
          const response = await api.booking.getBooking(filter);
          setRowData(response.result);
          setPagination(response.pagination);
        } else if (user?.role === "MARKETER" || "RESELLER") {
          const filter = {
            page: 1,
            sortField: "updatedAt",
            assigned_admin_object_id: user?._id,
          };
          console.log("====>api", filter);
          const response = await api.booking.getBooking(filter);
          setRowData(response.result);
          setPagination(response.pagination);
        } else {
          const filter = {
            page: 1,
            sortField: "updatedAt",
          };
          console.log("====>api", filter);
          const response = await api.booking.getBooking(filter);
          setRowData(response.result);
        }
      }, [pagination.currentPage, filters, builderId]);

	useEffect(() => {
		getEnquiryData();
	}, [getEnquiryData]);

	return { rowData, loading };
};

export default useGetCustomerEnquiryDetails;
