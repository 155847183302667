export const addZero = (num: number): string => {
    return num < 10 ? "0" + num : num.toString();
}
export const todayDate = (): string => {
    const date = new Date();
    const formattedDate = date.getFullYear() + "-" + (addZero(date.getMonth() + 1)) + "-" + addZero(date.getDate());
    return formattedDate;
}

export const sevenDayPrev = (givendate: Date | string): string => {
    const date = new Date(givendate);
    date.setDate(date.getDate() - 7);
    const formattedDate = date.getFullYear() + "-" + (addZero(date.getMonth() + 1)) + "-" + addZero(date.getDate());
    return formattedDate;
}

export const sevenDayNext = (givendate: Date | string): string => {
    const date = new Date(givendate);
    date.setDate(date.getDate() + 7);
    const formattedDate = date.getFullYear() + "-" + (addZero(date.getMonth() + 1)) + "-" + addZero(date.getDate());
    return formattedDate;
}