import { useCallback, useEffect, useState } from "react";
import { api } from "../../api";
import { IDashboard } from "../../../@types/interface/dashboardData.interface";
import { Params } from "../../../@types/api/api.types";

const useGetDailyLeads = ({date}:{date: string}) => {
  const [leadsData, setLeadsData] = useState<any[]>([]);

  const getDailyLeads = useCallback(async () => {
    const filter = {
      date
    }
    const response = await api.dashboard.getDailyLeadsCount(filter);
    if (response) {
      console.log("===>filter", filter);
      setLeadsData(response);
    }
  }, [date]);

  useEffect(() => {
    getDailyLeads();
  }, [getDailyLeads]);

  return { leadsData };
};

export default useGetDailyLeads;
