import React, { useState } from "react";
import { UpdatedPlotColDefs } from "./updatedPlotColDefs/UpdatedPlotColDefs";
import { ILayout } from "../../../../../@types/interface/layout.interface";
import DataGrid from "../../../../shared/dataGrid/DataGrid";

const UpdatedPlotDetails = ({
  plotDetails,
  onFilterChange,
}: {
  plotDetails: ILayout[];
  onFilterChange: (filters: any) => void;
}) => {
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);

  const handleRowSelect = (rowId: string) => {
    const data = {
      rowId,
      timestamp: Date.now(),
    };
    sessionStorage.setItem("selectedRow", JSON.stringify(data));
  };

  return (
    <div>
      <DataGrid
        rowData={plotDetails}
        colDefs={UpdatedPlotColDefs}
        onFilterChange={onFilterChange}
        selectedRowId={selectedRowId}
        onRowSelected={handleRowSelect}
      />
    </div>
  );
};

export default UpdatedPlotDetails;
