import React, { useContext, useEffect, useState } from "react";
import { FilterModel } from "ag-grid-community";
import UIContext from "../../../../../contexts/uiContext/UIContext";
import { IPagination } from "../../../../../@types/interface/pagination";
import useQuerySearch from "../../../../hooks/querySearch/useQuerySearch";
import useGetEnquiryIframeDetails from "../../../../hooks/enquiryIframeDetails/useGetEnquiryIframeDetails";
import DataGrid from "../../../../shared/dataGrid/DataGrid";
import { EnquiryIframeColDefs } from "../../enquiryIframe/enquiryIframeColdefs/enquiryIframeColdefs";
import { enquiryColumnDef } from "../../../../../constants/enquiry/EnquiryColDef";

const WebsiteLeads = () => {
  const { setDashboardHeader } = useContext(UIContext);
  const [filters, setFilters] = useState([]);

  const builderId = useQuerySearch("cid");
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    pageCount: 1,
  });

  const { rowData: enquiryIframeDetails } = useGetEnquiryIframeDetails(
    filters,
    pagination,
    setPagination,
    builderId || ""
  );

  const onFilterChange = (filterModel: FilterModel) => {
    setFilters((prevFilters: any) => {
      const sanitizedFilters = { ...prevFilters };
      Object.keys(sanitizedFilters).forEach((key: any) => {
        if (!filterModel[key]) {
          delete sanitizedFilters[key];
        }
      });
      const updatedFilters = { ...sanitizedFilters, ...filterModel };
      console.log("Updated Filters-->", updatedFilters);
      return updatedFilters;
    });
  };
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPagination((prev) => ({
      ...prev,
      currentPage: value,
    }));
  };

  useEffect(() => {
    setDashboardHeader("Website Leads");
  }, [setDashboardHeader]);
  console.log("===>enquiry", enquiryIframeDetails);
  return (
    <div>
      <DataGrid rowData={enquiryIframeDetails} colDefs={enquiryColumnDef} />
    </div>
  );
};

export default WebsiteLeads;
