import { useCallback, useContext, useEffect, useState } from "react";
import { IPagination } from "../../../@types/interface/pagination";
import AuthContext from "../../../contexts/authContext/authContext";
import { ROLES } from "../../../constants/roles/Roles";
import { IBooking } from "../../../@types/interface/booking.interface";
import { api } from "../../../utils/api";
import { formatFilters } from "../../../utils/commonFunction/formatFilter";
import useQuerySearch from "../../../components/hooks/querySearch/useQuerySearch";

const useGetAllChangeRequests= (
	pagination: IPagination,
	setPagination: React.Dispatch<React.SetStateAction<IPagination>>,
) => {
	const [rowData, setRowData] = useState<IBooking[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const getAllChangeRequest = useCallback(async () => {
        try{
            const response = await api.project.getChangeRequests({});
          setRowData(response.result);
          setPagination(response.pagination);
        } catch(error){
            console.error("Error while fetching data:", error);
        }
       
        
         
        
      }, [pagination.currentPage]);

	useEffect(() => {
		getAllChangeRequest();
	}, [getAllChangeRequest]);

	return { rowData, loading };
};

export default useGetAllChangeRequests;
