/*eslint-disable*/

import React, { useCallback, useContext, useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import { Button, Chip } from "@mui/material";
// import { IAddplots } from "../../../../../@types/interface/Builder.interface";
// import { api } from "../../../../../utils/api";
// import BuilderContext from "../../../../../contexts/builderContext/BuilderContext";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
// import UIContext from "../../../../../contexts/uiContext/UIContext";
import UIContext from "../../../../../../contexts/uiContext/UIContext";
import { IViewProjectProps } from "../../../../../../@types/props/ViewProject.props";
import { IProject } from "../../../../../../@types/interface/Projects";
import { type } from "@testing-library/user-event/dist/type";
import { defaultProjectForm } from "../../../../../../defaultValue/projectForm/ProjectForm";
import { ICustomer } from "../../../../../../@types/interface/Customer.interface";

const CustomerDetails = ({ customer }: { customer: ICustomer }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState<"success" | "error">(
    "success"
  );
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const [readOnly, setReadOnly] = useState<boolean>(true);
  const [details, setDetails] = useState<IProject>(defaultProjectForm);
  const { setDashboardHeader } = useContext(UIContext);
 
  const handleClick = () => {
    setReadOnly(!readOnly);
  };

  useEffect(() => {
    setDashboardHeader("View Project Details");
  }, [setDashboardHeader]);

  return (
    <form>
      <div>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={handleCloseSnackbar}
            severity={snackbarType}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              alignItems: "right",
            }}
          >
            <Button
              className="btn"
              variant="contained"
              onClick={handleClick}
              endIcon={<EditOutlinedIcon />}
            >
              Edit
            </Button>
          </div>
          <Accordion
            defaultExpanded
            style={{ display: "block", marginTop: "20px" }}
            elevation={4}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            ></AccordionSummary>
            <AccordionDetails>
              <div
                style={{
                  display: "flex",
                  alignItems: "left",
                  justifyContent: "space-between",
                }}
              >
                <label style={{ fontSize: "16px", marginLeft: "40px" }}>
                  Full Name:
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  defaultValue={customer.full_name}
                  sx={{
                    width: "60%",
                    marginRight: "40px",
                    marginBottom: "10px",
                  }}
                  inputProps={{ readOnly: readOnly }}
                  onChange={(event) => {
                    const value = event.target.value;
                    setDetails({
                      ...details,
                      project_name: value,
                    });
                  }}
                  required
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "left",
                  justifyContent: "space-between",
                }}
              >
                <label style={{ fontSize: "16px", marginLeft: "40px" }}>
                  Email:
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  name="email"
                  value={customer.email || "N/A"}
                  sx={{
                    width: "60%",
                    marginRight: "40px",
                    marginBottom: "10px",
                  }}
                  inputProps={{ readOnly: readOnly }}
                  onChange={(event) => {
                    const value = event.target.value;
                    setDetails({
                      ...details,
                      project_name: value,
                    });
                  }}
                  required
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "left",
                  justifyContent: "space-between",
                }}
              >
                <label style={{ fontSize: "16px", marginLeft: "40px" }}>
                  Phone:
                </label>
                <TextField
                  id="outlined-basic"
                  type="number"
                  variant="outlined"
                  value={customer.phone || "N/A"}
                  sx={{
                    width: "60%",
                    marginRight: "40px",
                    marginLeft: "20px",
                    marginBottom: "10px",
                  }}
                  required
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "left",
                  justifyContent: "space-between",
                }}
              >
                <label style={{ fontSize: "16px", marginLeft: "40px" }}>
                  Gender:
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  value={customer.gender || "N/A"}
                  sx={{
                    width: "60%",
                    marginRight: "40px",
                    marginLeft: "20px",
                    marginBottom: "10px",
                  }}
                  required
                />
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <br />
        <p style={{ fontSize: "16px", fontWeight: "500" }}>Address Details</p>
        <Accordion
          defaultExpanded
          style={{ display: "block", marginTop: "20px" }}
          elevation={4}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          ></AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                display: "flex",
                alignItems: "left",
                justifyContent: "space-between",
              }}
            >
              <label style={{ fontSize: "16px", marginLeft: "40px" }}>
                Address:
              </label>
              <textarea
                style={{
                  maxWidth: "615px",
                  minWidth: "615px",
                  maxHeight: "150px",
                  marginRight: "40px",
                  marginBottom: "10px",
                  padding: "5px",
                  fontSize: "16px",
                  outline: "1px",
                }}
                readOnly={readOnly}
                value={customer.address || "N/A"}
                required
              />
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </form>
  );
};

export default CustomerDetails;
