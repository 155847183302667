import { useCallback, useContext, useEffect, useState } from "react";
import { IPagination } from "../../../@types/interface/pagination";
import AuthContext from "../../../contexts/authContext/authContext";
import { ROLES } from "../../../constants/roles/Roles";
import { IBooking } from "../../../@types/interface/booking.interface";
import { api } from "../../../utils/api";
import { formatFilters } from "../../../utils/commonFunction/formatFilter";
import useQuerySearch from "../../../components/hooks/querySearch/useQuerySearch";

const useGetProjectWiseLead = (
	filters: any,
	pagination: IPagination,
    project_object_id: string,
	setPagination: React.Dispatch<React.SetStateAction<IPagination>>,
) => {
    const {user} = useContext(AuthContext);
	const [rowData, setRowData] = useState<IBooking[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
    const builderId = useQuerySearch("cid");
	const getEnquiryData = useCallback(async () => {
        if (
          user?.role === ROLES.builder_admin ||
          ROLES.super_admin ||
          ROLES.project_manager ||
          ROLES.channel_partner ||
          ROLES.telecaller
        ) {
          const formattedFilter = formatFilters(filters);
          console.log("formattedFilter", formattedFilter);
          const filter = {
            ...formattedFilter,
            project_object_id,
            builder_object_id: builderId,
          };
          console.log("====>api", filter);
          const response = await api.iframe.getProjectWiseLeads(filter);
          setRowData(response.result);
          setPagination(response.pagination);
        } else if (user?.role === "MARKETER" || "RESELLER") {
          const filter = {
            page: 1,
            sortField: "updatedAt",
            project_object_id,
            assigned_admin_object_id: user?._id,
          };
          const response = await api.iframe.getProjectWiseLeads(filter);
          setRowData(response.result);
          setPagination(response.pagination);
        } else {
          const filter = {
            page: 1,
            sortField: "updatedAt",
            project_object_id
          };
          console.log("====>api", filter);
          const response = await api.iframe.getProjectWiseLeads(filter);
          setRowData(response.result);
          setPagination(response.pagination);
        }
      }, [pagination.currentPage, filters, builderId, project_object_id]);

	useEffect(() => {
		getEnquiryData();
	}, [getEnquiryData]);

	return { rowData, loading };
};

export default useGetProjectWiseLead;
