import {
  Accordion,
  AccordionDetails,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  TextField,
} from "@mui/material";
import React, {
  ChangeEventHandler,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import UIContext from "../../../../contexts/uiContext/UIContext";
import SendIcon from "@mui/icons-material/Send";
import useGetAllProjectName from "../../../../utils/hooks/useGetAllProjectName/useGetAllProjectName";
import BuilderContext from "../../../../contexts/builderContext/BuilderContext";
import { api } from "../../../../utils/api";
import { IProjectChangeRequest } from "../../../../@types/interface/changeRequest.interface";
import AuthContext from "../../../../contexts/authContext/authContext";
import { useNavigate } from "react-router-dom";
import { useCreateChangeRequest } from "../../../../utils/hooks/useCreateChangeRequest/useCreateChangeRequest";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const AddChangeReq = () => {
  const navigate = useNavigate();
  const { setDashboardHeader } = useContext(UIContext);
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const { allProjectList } = useGetAllProjectName();
  console.log("---response", allProjectList);
  const [selectedProject, setSelectedProject] = useState("");
  const [changeDetails, setChangeDetails] = useState<IProjectChangeRequest>();
  const { createChangeRequest, isLoading, error } = useCreateChangeRequest();

  const handleProjectChange = (
    event: SelectChangeEvent<string>,
    child: ReactNode
  ) => {
    setSelectedProject(event.target.value as string);
  };


    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { name, value },
      } = event;
      setChangeDetails(Object.assign({}, changeDetails, { [name]: value }));
    }, [changeDetails]);





const handleSubmit = (event: React.FormEvent) => {
  event.preventDefault();
  createChangeRequest(changeDetails, selectedProject, uploadedFile);
};
console.log("response", selectedProject);
  useEffect(() => {
    setDashboardHeader("Project Change Request");
  }, [setDashboardHeader]);

  return (
    <>
      <div>
        <h3>Add Change Request</h3>
        <Accordion defaultExpanded sx={{ marginTop: "20px" }}>
          <AccordionDetails>
            <div className="input-field-container">
              <label>Project Name:</label>
              <FormControl sx={{ width: "700px" }} variant="outlined">
                <InputLabel id="project-select-label">
                  Select Project
                </InputLabel>
                <Select
                  labelId="project-select-label"
                  id="project-select"
                  value={selectedProject}
                  onChange={handleProjectChange}
                  name="project_object_id"
                  label="Select Project"
                >
                  {allProjectList
                    ? allProjectList.map((project) => (
                        <MenuItem
                          key={project._id}
                          value={project._id}
                        >
                          {project.project_name}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </div>

            <div
              className="input-field-container"
            >
              <label>Change Request Subject:</label>
              <TextField
                id="change-details"
                variant="outlined"
                onChange={handleChange}
                multiline
                name="subject"
                maxRows={10} // Optional: Set a maximum number of rows for expansion
                sx={{ width: "700px", marginBottom: "20px" }}
              />
            </div>
            <div
              className="input-field-container"
            >
              <label>Change Details:</label>
              <TextField
                id="change-details"
                variant="outlined"
                onChange={handleChange}
                multiline
                name="change_details"
                maxRows={10} // Optional: Set a maximum number of rows for expansion
                sx={{ width: "700px", marginBottom: "20px" }}
              />
            </div>
            <div className="input-field-container">
              <label>Supported Documents:</label>
              <div className="logo-container">
                <Button
                  style={{
                    marginTop: 20,
                    marginBottom: 10,
                    backgroundColor: "#49BB43",
                  }}
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  className="btn"
                >
                  Upload documents
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(event) => {
                      const file = event.target.files?.[0];
                      if (file) {
                        setUploadedFile(file);
                      }
                    }}
                    required
                  />
                </Button>

                {uploadedFile && (
                  <Chip
                    label={uploadedFile.name}
                    onDelete={() => setUploadedFile(null)}
                    variant="outlined"
                    sx={{ marginTop: 1 }}
                  />
                )}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="button-container">
        <Button
          variant="contained"
          className="btn"
          onClick={handleSubmit}
          sx={{ marginLeft: "20px", marginBottom: "20px", marginTop: "20px" }}
          endIcon={<SendIcon />}
        >
          Submit
        </Button>
      </div>
    </>
  );
};

export default AddChangeReq;
