import { useCallback, useEffect, useState } from "react";
import { api } from "../../api";
import { IDashboard } from "../../../@types/interface/dashboardData.interface";

const useGetDashboard = () => {
  const [dashboardData, setDashboardData] = useState<IDashboard | null>(null);

  const getDashboardData = useCallback(async () => {
    const response = await api.dashboard.getDashboard({});
    if (response) {
      setDashboardData(response);
    }
  }, []);

  useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);

  return { dashboardData };
};

export default useGetDashboard;
