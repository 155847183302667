import React, { useContext, useEffect } from 'react'
import UIContext from '../../../contexts/uiContext/UIContext';

const PlotsManagement = () => {
  const {setDashboardHeader} = useContext(UIContext);

    useEffect(() => {
        setDashboardHeader("Plots Management")
    },[setDashboardHeader])

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
      <h1>Coming Soon</h1>
    </div>
  )
}

export default PlotsManagement