import { Avatar } from "@mui/material";
import { IBooking } from "../../../../../@types/interface/booking.interface";
import { IProjectChangeRequest } from "../../../../../@types/interface/changeRequest.interface";
import { IProjectName } from "../../../../../@types/interface/projectName.interface";
import { useContext } from "react";
import AuthContext from "../../../../../contexts/authContext/authContext";

const RequestByCellRenderer = () => {
    const {user} = useContext(AuthContext);
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      

      <span style={{ marginLeft: 4 }}>{user?.full_name}</span>
    </div>
  );
};

export default RequestByCellRenderer;
