import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import UIContext from "../../../../contexts/uiContext/UIContext";
import SendIcon from '@mui/icons-material/Send';
import DownloadButton from "../../../shared/downloadbtn/Download";
import BuilderContext from "../../../../contexts/builderContext/BuilderContext";
import { api } from "../../../../utils/api";

const EmployeeReport = () => {
  const { setDashboardHeader } = useContext(UIContext);
  const {builderDetails} = useContext(BuilderContext);
  const [alternativeReportName, setAlternativeReportName] = useState<string>("");
  
  const handleChange = (event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setAlternativeReportName(event.target.value as string);
  }

   const getFullEmployeeList = async () => {
      const filter = {
        page: 0,
        sortField: "updatedAt",
        builder_object_id: builderDetails?._id || "",
      };
      const response = await api.admin.getEmployeeList(filter);
      if(response){
        return response;
      }
    
    };
  useEffect(() => {
    setDashboardHeader("Employee Report");
  }, [setDashboardHeader]);
  return (
    <>
    <div>
      <h3>Employee Report Generation</h3>
      <Accordion defaultExpanded sx={{ marginTop: "20px" }}>
        <AccordionDetails>
         
          
          <div
            className="input-field-container"
          >
            <label>Alternative Report Name:</label>
            <TextField
              id="outlined-basic"
              variant="outlined"
              sx={{ width: "600px", marginBottom: "20px" }}
            />
          </div>
        </AccordionDetails>

      </Accordion>
    </div>
    <div className="button-container" style={{marginTop: "20px"}}>
    <DownloadButton
          
          apiCall={getFullEmployeeList
          }
          fileName={`${
            alternativeReportName? alternativeReportName : "Employee"
          } Report`}
          button_name="Download Employee Report"
          key={"download_projects"}
        />
    </div>
    </>
  );
};

export default EmployeeReport;