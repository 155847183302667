import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../api";
import AuthContext from "../../../contexts/authContext/authContext";
import BuilderContext from "../../../contexts/builderContext/BuilderContext";

export const useCreateChangeRequest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const {user} = useContext(AuthContext);
  const {builderDetails} = useContext(BuilderContext);

  const createChangeRequest = async (
    changeDetails: any,
    selectedProject: string,
    uploadedFile: File | null
  ) => {
    setIsLoading(true);
    setError(null);

    try {
      const formData = new FormData();
      formData.append(
        "requestDetails",
        JSON.stringify({
          ...changeDetails,
          request_by: user?._id,
          project_object_id: selectedProject,
          builder_object_id: builderDetails?._id,
        })
      );

      if (uploadedFile) {
        formData.append("supported_doc", uploadedFile);
      }

      const response = await api.project.createChangeRequest(formData);
      if (response) {
        navigate("/admin/projects/change-req");
        alert("Request created successfully");
      } else {
        throw new Error(`API request failed with status ${response.status}`);
      }
    } catch (err: any) {
      console.error("Error while adding data:", err);
      setError(err.message || "An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  return { createChangeRequest, isLoading, error };
};
