import { Label } from "@mui/icons-material";

export const LANDTYPES_TYPE_OPTIONS: { label: string; value: string }[] = [
  {
    label: "Residential",
    value: "RESIDENTIAL",
  },
  {
    label: "Commercial",
    value: "COMMERCIAL",
  },
  {
    label: "Industrial",
    value: "INDUSTRIAL",
  },
  {
    label: "Farm Land",
    value: "FARMLAND",
  },
];

export const AREATYPES_TYPES_OPTIONS = [
  {
    label: "Land",
    value: "LAND",
  },
  {
    label: "Land + Farm House",
    value: "LAND+FARMHOUSE",
  },
  {
    label: "Land + Plants",
    value: "LAND+PLANTS",
  },
  {
    label: "Land + Farming",
    value: "LAND+FARMING",
  },
  {
    label: "Land + Villa",
    value: "LAND+VILLA",
  },
  {
    label: "Land + Villa + 1st Floor",
    value: "LAND+VILLA+1STFLOOR",
  },
  {
    label: "Land + Villa + G + 1",
    value: "LAND+VILLA+G+1",
  },
  {
    label: "Land + Villa + G + 1 +2",
    value: "LAND+VILLA+G+1+2",
  },
];

export const PLOT_STATUS_OPTIONS = [
  {
    label: "Available",
    value: "AVAILABLE",
  },
  {
    label: "Booked",
    value: "BOOKED",
  },
  {
    label: "Hold",
    value: "HOLD",
  },
  {
    label: "Blocked",
    value: "BLOCKED",
  }
  
];
