import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useContext, useEffect, useState } from "react";
import UIContext from "../../../../contexts/uiContext/UIContext";
import { enquiryColumnDef } from "../../../../constants/enquiry/EnquiryColDef";
import { IPagination } from "../../../../@types/interface/pagination";
import BasicPagination from "../../../shared/basicPagination/BasicPagination";
import useQuerySearch from "../../../hooks/querySearch/useQuerySearch";
import useGetCustomerEnquiryDetails from "../../../hooks/customerEnquiryDetails/useGetCustomerEnquiryDetails";
import { FilterModel } from "ag-grid-community";

const NewEnquiry = () => {
  const { setDashboardHeader } = useContext(UIContext);
  const [filters, setFilters] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    pageCount: 1,
  });

  const builderId = useQuerySearch("cid");

  const { rowData: bookingList } = useGetCustomerEnquiryDetails(
    filters,
    pagination,
    setPagination,
    builderId || ""
  );

  const onRowClicked = (event: { data: any }) => {
    const rowData = event.data;
    setSelectedRowData(rowData);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPagination((prev) => ({
      ...prev,
      currentPage: value,
    }));
  };
  
    const onFilterChange = (filterModel: FilterModel) => {
      setFilters((prevFilters: any) => {
        const sanitizedFilters = { ...prevFilters };
        Object.keys(sanitizedFilters).forEach((key: any) => {
          if (!filterModel[key]) {
            delete sanitizedFilters[key];
          }
        });
        const updatedFilters = { ...sanitizedFilters, ...filterModel };
        console.log("Updated Filters-->", updatedFilters);
        return updatedFilters;
      });
    };

  useEffect(() => {
    setDashboardHeader("All Customer Enquiry");
  }, [setDashboardHeader]);

  return (
    <div>
      <div className="ag-theme-alpine" style={{ height: 300, width: "100%" }}>
        <AgGridReact
          rowData={bookingList}
          columnDefs={enquiryColumnDef}
          onRowClicked={onRowClicked}
          onFilterChanged={onFilterChange}
        />
        <BasicPagination
          currentPage={pagination.currentPage}
          handlePageChange={handlePageChange}
          pageCount={pagination.pageCount}
        />
        {/* <BasicPagination /> */}
      </div>
    </div>
  );
};

export default NewEnquiry;
