import { useState, useCallback, useEffect } from 'react';
import { formatFilters } from '../../commonFunction/formatFilter';
import { api } from '../../api';
import useQuerySearch from '../../../components/hooks/querySearch/useQuerySearch';
import { IProjectName } from '../../../@types/interface/projectName.interface';

const useGetAllProjectName = ( ) => {
    const [allProjectList, setAllProjectList] = useState<IProjectName[]>();
    
    const builderId = useQuerySearch("cid")

    const getAllProjects = useCallback(async () => {
        try {
            let response: any = {};
            const filter = {
                builder_object_id: builderId
            };
            response = await api.project.getAllProjects(filter);
           
            if (response) {
                setAllProjectList(response);
                console.log("response", response);
            }
        } catch (error) {
            console.error("Error while fetching data:", error);
        }
    }, [builderId]);

    useEffect(() =>{
        getAllProjects()
    }, [getAllProjects])

    return { allProjectList };
};

export default useGetAllProjectName;