import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { ChangeEventHandler, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import UIContext from "../../../../contexts/uiContext/UIContext";
import SendIcon from '@mui/icons-material/Send';
import DownloadButton from "../../../shared/downloadbtn/Download";
import useQuerySearch from "../../../hooks/querySearch/useQuerySearch";
import useGetCustomerEnquiryDetails from "../../../hooks/customerEnquiryDetails/useGetCustomerEnquiryDetails";
import useGetEnquiryIframeDetails from "../../../hooks/enquiryIframeDetails/useGetEnquiryIframeDetails";
import { IPagination } from "../../../../@types/interface/pagination";
import { api } from "../../../../utils/api";
import { ROLES } from "../../../../constants/roles/Roles";
import AuthContext from "../../../../contexts/authContext/authContext";

const LeadReport = () => {
  const { setDashboardHeader } = useContext(UIContext);
  const [selectedLeadSource, setSelectedLeadSource] = useState<string>("");
  const {user} = useContext(AuthContext)
  const [alternativeReportName, setAlternativeReportName] = useState<string>("");
const [filters, setFilters] = useState([]);
const [pagination, setPagination] = useState<IPagination>({ currentPage: 1, pageCount: 1 });
  const handleLeadChange = (event: SelectChangeEvent<string>, child: ReactNode) => {
    setSelectedLeadSource(event.target.value as string);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setAlternativeReportName(event.target.value as string);
  };

  const builderId = useQuerySearch("cid");
  
  // const { rowData: bookingList } = useGetCustomerEnquiryDetails(
  //   filters,
  //   pagination,
  //   setPagination,
  //   builderId || ""
  // );


  // const { rowData: enquiryIframeDetails } = useGetEnquiryIframeDetails(
  //   filters,
  //   pagination,
  //   setPagination,
  //   builderId || ""
  // );

  const getEnquiryData = useCallback(async () => {
          if (
            user?.role === ROLES.builder_admin ||
            ROLES.super_admin ||
            ROLES.project_manager ||
            ROLES.channel_partner ||
            ROLES.telecaller
          ) {
            const filter = {
              builder_object_id: builderId,
            };
            console.log("====>api", filter);
            const response = await api.booking.getBooking(filter);
            if(response){
              return response;
            }
            
          } else if (user?.role === "MARKETER" || "RESELLER") {
            const filter = {
              page: 1,
              sortField: "updatedAt",
              assigned_admin_object_id: user?._id,
            };
            console.log("====>api", filter);
            const response = await api.booking.getBooking(filter);
            if(response){
              return response;
            }
          } else {
           
            const response = await api.booking.getBooking({});
          }
        }, [builderId]);
  

        const fetchEnquiryIframe = useCallback(async () => {
            try {
              const filter = {
               
                builder_object_id: builderId,
              };
              const response = await api.iframe.getEnquiryIframe(filter);
              if (response) {
                return response;
              }
            } catch (error) {
              console.error("error:", error);
            }
          }, [builderId]);
        
          useEffect(() => {
            fetchEnquiryIframe();
          }, [fetchEnquiryIframe]);
    useEffect(() => {
      getEnquiryData();
    }, [getEnquiryData]);
  useEffect(() => {
    setDashboardHeader("Leads Report");
  }, [setDashboardHeader]);
  return (
    <>
    <div>
      <h3>Lead Report Generation</h3>
     
      <Accordion defaultExpanded sx={{ marginTop: "20px", padding: "20px" }}>
        <AccordionDetails>
          <div className="input-field-container">
          <label >Select Lead Source:</label>
              <FormControl sx={{width: "600px"}} variant="outlined">
                <InputLabel id="project-select-label">
                  Select lead source
                </InputLabel>
                <Select
                  labelId="project-select-label"
                  id="project-select"
                  value={selectedLeadSource}
                  onChange={handleLeadChange}
                  label="Select Project"
                >
                <MenuItem value="incorners-leads">Incorners Leads</MenuItem>
                <MenuItem value="website-leads">Website Leads</MenuItem>
                </Select>
              </FormControl>
          </div>
       
         
          <div
           className="input-field-container"
          >
            <label>Alternative Report Name:</label>
            <TextField
              id="outlined-basic"
              variant="outlined"
              onChange={handleChange}
              sx={{ width: "600px", marginBottom: "20px" }}
            />
          </div>
          </AccordionDetails>
      </Accordion>
    </div>
   
    
    <div className="button-container" style={{marginTop: "20px"}}>
    <DownloadButton
          
          apiCall={selectedLeadSource === "website-leads" ? fetchEnquiryIframe :
            getEnquiryData
          }
          fileName={`${
            alternativeReportName? alternativeReportName : selectedLeadSource
          } leads Report`}
          button_name="Download Leads Report"
          key={"download_projects"}
        />
    </div>
    </>
       
  );
};

export default LeadReport;