import React from "react";
import { IProjectChangeRequest } from "../../../../../@types/interface/changeRequest.interface";

const DateCellRenderer = ({ data }: { data: IProjectChangeRequest }) => {
    return (
        <div>
            <span> {data.createdAt ? new Date(data.createdAt).toLocaleString() : "N/A"}</span>
        </div>
    );
};

export default DateCellRenderer;
